export function useApplicationAnswersMock() {
  return [
    {
      applicationDid: 'did:ceramic:fd8h0f9dh90fh',
      answers: {
        '1234': 'Gail Wilson',
        '1235': '1 yr',
        '1236':
          'I love community and people so would love to help build out the events and Discord to make it an absolutely thriving place!',
        '1237': '1-4 hrs',
      },
      roleId: 'ja09dhdfd9hzdd',
      formId: 'did:ceramic:abcdefghijklmnopqrstuvwxyz',

      // Fetched via formId to show to Frontend
      form: {
        id: 'nns0fdn09f',
        reference: 'ref',
        name: 'First form',
        description: 'This is the first form we built.',
        fields: [
          {
            id: '1234',
            type: 'text', // text | email | phone_number | address | select | number
            label: 'What is your name?',
            sub_label: 'Full name please',
            required: true,
          },
          {
            id: '1235',
            type: 'number', // text | email | phone_number | address | select | number
            label: 'How many years of experience do you have in web3?',
            sub_label:
              'All experience levels are welcome! We just want to get a sense of how we can help transition you into our DAO.',
            options: null,
            required: null,
          },
          {
            id: '1236',
            type: 'text', // text | email | phone_number | address | select | number
            label: 'How would you contribute?',
            sub_label: null,
            required: true,
          },
          {
            id: '1237',
            type: 'select', // text | email | phone_number | address | select | number
            label: 'How many hours per week could you contribute?',
            sub_label: null,
            required: null,
            options: {
              choices: [
                { id: '1', label: '1-4 hrs' },
                { id: '2', label: '5-10 hrs' },
                { id: '3', label: '11-20 hrs' },
                { id: '4', label: '>20 hrs' },
              ],
              multiple: null,
            },
          },
        ],
      },
    },
    {
      applicationDid: 'did:ceramic:fd8h0f9dh90fg',
      answers: {
        '1234': 'Carlos Diaz-Padron',
        '1235': '4 yrs',
        '1236': 'I am an engineer so I would like to contribute my skills on projects.',
        '1237': '>20 hrs',
      },
      roleId: 'ja09dhdfd9hzdd',
      formId: 'did:ceramic:abcdefghijklmnopqrstuvwxyz',

      // Fetched via formId to show to Frontend
      form: {
        id: 'nns0fdn09f',
        reference: 'ref',
        name: 'First form',
        description: 'This is the first form we built.',
        fields: [
          {
            id: '1234',
            type: 'text', // text | email | phone_number | address | select | number
            label: 'What is your name?',
            sub_label: 'Full name please',
            required: true,
          },
          {
            id: '1235',
            type: 'number', // text | email | phone_number | address | select | number
            label: 'How many years of experience do you have in web3?',
            sub_label:
              'All experience levels are welcome! We just want to get a sense of how we can help transition you into our DAO.',
            options: null,
            required: null,
          },
          {
            id: '1236',
            type: 'text', // text | email | phone_number | address | select | number
            label: 'How would you contribute?',
            sub_label: null,
            required: true,
          },
          {
            id: '1237',
            type: 'select', // text | email | phone_number | address | select | number
            label: 'How many hours per week could you contribute?',
            sub_label: null,
            required: null,
            options: {
              choices: [
                { id: '1', label: '1-4 hrs' },
                { id: '2', label: '5-10 hrs' },
                { id: '3', label: '11-20 hrs' },
                { id: '4', label: '>20 hrs' },
              ],
              multiple: null,
            },
          },
        ],
      },
    },
  ];
}

export function useSubmittedApplicationsMock() {
  return [
    {
      applicationDid: 'did:ceramic:fd8h0f9dh90fh',
      userId: '0xEE599e7c078cAdBB7f81531322d6534F22749136',
      roleId: 'ja09dhdfd9hzdd',
      formDid: 'did:ceramic:abcdefghijklmnopqrstuvwxyz',
      formId: 'nns0fdn09f',
    },
    {
      applicationDid: 'did:ceramic:fd8h0f9dh90fg',
      userId: '0x9b6568d72a6f6269049fac3998d1fadf1e6263cc',
      roleId: 'ja09dhdfd9hzdd',
      formDid: 'did:ceramic:abcdefghijklmnopqrstuvwxyz',
      formId: 'nns0fdn09f',
    },
  ];
}

export function fetchSubmittedApplicationMock(applicationDid: string) {
  const statuses: { [key: string]: { status: string } } = {
    pending: {
      status: 'pending',
    },
    accepted: {
      status: 'accepted',
    },
  };

  return statuses[applicationDid];
}
