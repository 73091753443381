import { AntDesign } from '@expo/vector-icons';
import { PageContainer, createStyles, Typography, Button, VideoDisplaySection, Loading } from '@metaphor-xyz/ui';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { View, Image } from 'react-native';
import { Divider } from 'react-native-paper';

import videoPlaceholder from '../assets/videoPlaceholder.png';
import InfoCardsSection from '../components/OverviewForDAO/InfoCardsSection';
import PagesSection from '../components/OverviewForDAO/PagesSection';
import RolesSection from '../components/OverviewForDAO/RolesSection';
import StatsSection from '../components/OverviewForDAO/StatsSection';
import TitleAndMissionView from '../components/OverviewForDAO/TitleAndMission';
import { useBreakpoint } from '../hooks/useBreakpoint';
import useOrganization from '../hooks/useOrganization';
import { StackParams } from '../navigation';

export default function OverviewForDAO({ route, navigation }: StackScreenProps<StackParams, 'overview'>) {
  const styles = useStyles();
  const breakpoint = useBreakpoint();
  const isBreakpointSmAndBelow = breakpoint === 'xs' || breakpoint === 'sm';

  const onPressMembership = useCallback(
    (_membershipType: string) => () => {
      navigation.navigate('onboard', {
        orgEnsName: route.params.orgEnsName,
        // TODO: Add back in preset of chosen role when fixed
        // membershipType,
      });
    },
    [navigation, route.params.orgEnsName]
  );

  const { loading, organization } = useOrganization(route.params.orgEnsName);
  const daoImage = organization?.images?.banner || undefined;

  if (loading || !organization) {
    return <Loading size="large" />;
  }

  return (
    <>
      <PageContainer maxWidth={1200} paddingOverride={isBreakpointSmAndBelow ? 0 : undefined}>
        <View style={styles.content}>
          <View style={[styles.titleAndMissionContainer, isBreakpointSmAndBelow && styles.paddingRightAndLeft]}>
            <TitleAndMissionView title={organization.name} mission={organization.description} />
          </View>

          <View style={[styles.daoImage, isBreakpointSmAndBelow && styles.daoImageSm]}>
            <Image style={{ height: '100%' }} source={{ uri: daoImage }} />
          </View>

          <View style={[styles.actionRowsContainer, isBreakpointSmAndBelow && styles.actionRowsContainerSm]}>
            <RolesSection roles={organization.roles || []} onPressRole={onPressMembership} />
          </View>

          <View style={[styles.statsContainer, isBreakpointSmAndBelow && styles.statsContainerSm]}>
            <StatsSection stats={[]} />
          </View>

          <View style={styles.dividerContainer}>
            <Divider />
          </View>

          <View style={[styles.calloutContainer, isBreakpointSmAndBelow && styles.paddingRightAndLeft]}>
            <Typography type="h2" style={{ maxWidth: 431 }}>
              A community for the next 1 million DAO operators
            </Typography>
            {!isBreakpointSmAndBelow && (
              <Button
                size="lg"
                title="Jump in"
                postTextComponent={<AntDesign style={styles.buttonIcon} name="arrowright" size={28} />}
              />
            )}
          </View>

          <View style={[styles.pagesSection, isBreakpointSmAndBelow && styles.paddingRightAndLeft]}>
            <PagesSection />
          </View>
        </View>

        <View style={[styles.videoContainer, isBreakpointSmAndBelow && styles.paddingRightAndLeft]}>
          <VideoDisplaySection videoUri={videoPlaceholder} videoTitle="Joining a DAO for the first time?" />
        </View>

        <View style={[styles.infoCardSection, isBreakpointSmAndBelow && styles.paddingRightAndLeft]}>
          <InfoCardsSection />
        </View>
      </PageContainer>
    </>
  );
}

const useStyles = createStyles(theme => ({
  paddingRightAndLeft: {
    paddingRight: 24,
    paddingLeft: 24,
  },
  content: { maxWidth: 1129 },
  titleAndMissionContainer: {
    marginTop: 100,
  },

  daoImage: {
    width: 455,
    height: 438,
    marginTop: 300,
    position: 'absolute',
    zIndex: -1,
  },
  daoImageSm: {
    marginTop: 16,
    position: undefined,
    height: 352,
    width: '100%',
    zIndex: 0,
  },

  actionRowsContainer: {
    backgroundColor: theme.colors.background,
    marginTop: 200,
    alignSelf: 'flex-end',
    width: '100%',
    maxWidth: 750,
    borderRadius: 8,
  },
  actionRowsContainerSm: {
    marginTop: 0,
    borderRadius: 0,
    width: '100%',
    maxWidth: '100%',
  },

  statsContainer: {
    marginTop: 215,
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 32,
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '100%',
    flexWrap: 'wrap',
  },
  statsContainerSm: {
    flexDirection: 'column',
    marginTop: 52,
    justifyContent: 'center',
    gap: 60,
    alignItems: 'center',
    alignSelf: 'center',
    paddingRight: 24,
    paddingLeft: 24,
  },

  dividerContainer: {
    paddingTop: 50,
    paddingBottom: 50,
  },

  calloutContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  pagesSection: {
    width: '100%',
    marginTop: 54,
  },

  videoContainer: {
    paddingTop: 60,
  },

  infoCardSection: {
    paddingBottom: 52,
  },

  buttonIcon: {
    marginLeft: 8,
    color: 'white',
  },
}));
