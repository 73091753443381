import { Button, createStyles, Dropzone, Input, PageContainer, Typography } from '@metaphor-xyz/ui';
import React from 'react';
import { Text, View } from 'react-native';
import { Checkbox, RadioButton } from 'react-native-paper';

interface TellUsAboutYourDaoProps {
  onNavigate: () => void;
}

export default function TellUsAboutYourDao({ onNavigate }: TellUsAboutYourDaoProps) {
  const styles = useStyles();

  const [daoName, setDaoName] = React.useState('');
  const [describeYourDao, setDescribeYourDao] = React.useState('');
  const [daoOneLiner, setDaoOneLiner] = React.useState('');
  const [colourScheme, setColourScheme] = React.useState('');
  const [audioVideoRecordingChecked, setAudioVideoRecordingChecked] = React.useState(false);

  return (
    <View>
      <PageContainer>
        <Typography type="h2" style={styles.lowerMargin}>
          Tell us about your DAO ️
        </Typography>

        <Typography type="body" style={styles.lowerMargin}>
          We’ll use this information to setup an onboarding introduction page
        </Typography>

        <View style={styles.lowerMargin}>
          <Input label="DAO Name" value={daoName} onChangeText={setDaoName} />
        </View>

        <View style={styles.lowerMargin}>
          <Input
            label="Describe your DAO"
            value={describeYourDao}
            onChangeText={setDescribeYourDao}
            placeholder="Who you are? Your mission?"
            multiline
            numberOfLines={5}
          />
        </View>

        <View style={styles.lowerMargin}>
          <Input
            label="DAO One Liner"
            value={daoOneLiner}
            onChangeText={setDaoOneLiner}
            placeholder="A short description of your DAO"
          />
        </View>

        <View style={styles.lowerMargin}>
          <Dropzone title="Your logo" />

          <Dropzone title="Your logo icon" />

          <Dropzone title="Community image" />
        </View>

        <View style={styles.lowerMargin}>
          <Typography type="body-bold" style={styles.lowerMargin}>
            Colour scheme
          </Typography>

          <RadioButton.Group onValueChange={newValue => setColourScheme(newValue)} value={colourScheme}>
            <View style={styles.radio}>
              <RadioButton value="dark" />
              <Text>Dark mode</Text>
            </View>
            <View style={styles.radio}>
              <RadioButton value="light" />
              <Text>Light mode</Text>
            </View>
          </RadioButton.Group>
        </View>

        <View style={styles.checkbox}>
          <Checkbox
            status={audioVideoRecordingChecked ? 'checked' : 'unchecked'}
            onPress={() => {
              setAudioVideoRecordingChecked(!audioVideoRecordingChecked);
            }}
          />
          <Text>Record a audio or video recoding for new members? (optional)</Text>
        </View>

        <View style={styles.completeButtonContainer}>
          <Button title="Complete" color="primary" onPress={onNavigate} />
        </View>
      </PageContainer>
    </View>
  );
}

const useStyles = createStyles(_theme => ({
  radio: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 32,
  },
  lowerMargin: {
    marginBottom: 16,
  },
  completeButtonContainer: {
    paddingBottom: 48,
  },
}));
