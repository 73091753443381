import { useWalletManager } from '@metaphor-xyz/hooks/dist';
import { createStyles, Input, Typography, WalletSelector } from '@metaphor-xyz/ui';
import React, { useState } from 'react';
import { Image, View } from 'react-native';

import { useDebounce } from '../../hooks/useDebounce';
import { useENSName } from '../../hooks/useENSName';

export default function ConnectEns() {
  const styles = useStyles();
  const { connected } = useWalletManager();
  const [ensName, setEnsName] = useState('');
  const debouncedEnsName = useDebounce(ensName);
  const { controllerAddress, avatar } = useENSName(debouncedEnsName);

  return (
    <View>
      <Typography type="h3" style={styles.lowerMargin}>
        What’s your DAO’s ENS name?
      </Typography>
      <Input label="ENS name" error={!!ensName && !controllerAddress} value={ensName} onChangeText={setEnsName} />
      {!!ensName && !controllerAddress && (
        <Typography color="error-red" type="info">
          Please enter a valid ENS name.
        </Typography>
      )}

      {controllerAddress && avatar && (
        <>
          <Typography type="info" color="secondary-black" style={{ marginTop: 30, marginBottom: 10 }}>
            Controller
          </Typography>

          <View style={styles.container}>
            <Image style={{ width: 28, height: 25, marginRight: 20 }} source={{ uri: avatar }} />
            <Typography type="body">{controllerAddress}</Typography>
          </View>

          {!connected && (
            <View>
              <Typography type="h3" style={{ marginTop: 30 }}>
                Connect your wallet
              </Typography>
              <Typography color="secondary-black" style={{ marginBottom: 20 }}>
                You’ve not connected xyz, connect as a controller.
              </Typography>
              <WalletSelector />
            </View>
          )}
        </>
      )}
    </View>
  );
}

const useStyles = createStyles(theme => ({
  lowerMargin: {
    marginBottom: 32,
  },
  container: {
    backgroundColor: theme.colors.defaultLightGray,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
}));
