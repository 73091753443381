import { useWalletManager } from '@metaphor-xyz/hooks/dist';
import { TopNav } from '@metaphor-xyz/ui';
import { StackHeaderProps } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import NotificationButtonAndDropdown from '../components/NotificationButtonAndDropdown';
import ProfileComponentAndDropdown from '../components/ProfileComponentAndDropdown';
import { OnboardingFlowStackParams, StackParams } from '../navigation';

export default function Header({ navigation, route }: StackHeaderProps) {
  const { connecting, mainAccount } = useWalletManager();
  const [tabs, setTabs] = useState<{ id: keyof StackParams; label: string }[]>([
    { id: 'home', label: 'Home' },
    { id: 'explore', label: 'Explore' },
  ]);

  useEffect(() => {
    if (
      (!connecting && mainAccount === '0xEE599e7c078cAdBB7f81531322d6534F22749136') ||
      mainAccount === '0x9b6568d72a6f6269049fac3998d1fadf1e6263cc' ||
      mainAccount === '0xe437144d0146dee09a3e99541d77c4fcee2dff40'
    ) {
      const tabsWithManagerTab: { id: keyof StackParams; label: string }[] = [
        { id: 'home', label: 'Home' },
        { id: 'explore', label: 'Explore' },
        { id: 'community', label: 'Manager' },
      ];

      setTabs(tabsWithManagerTab);
    }
  }, [connecting, mainAccount]);

  return (
    <TopNav
      items={tabs}
      onPress={navigation.navigate}
      activeItemId={route.name as keyof StackParams & OnboardingFlowStackParams}
      infoComponent={
        <View style={{ flexDirection: 'row' }}>
          <NotificationButtonAndDropdown />

          <ProfileComponentAndDropdown />
        </View>
      }
    />
  );
}
