import { InMemoryCache } from '@apollo/client';
import {
  EnyoSupergraph,
  ERC20Subgraph,
  EthersProvider,
  GraphProtocolSubgraph,
  EthersWalletSubgraph,
  Network,
  EnyoCeramicProvider,
  OrganizationsSubgraph,
} from '@metaphor-xyz/hooks';
import { ethers } from 'ethers';
import { Ed25519Provider } from 'key-did-provider-ed25519';

import approvalProtocolSubgraph from './subgraphSchemas/approvalProtocol';

const APPROVAL_PROTOCOL_SUBGRAPH = {
  local: 'metaphor-xyz/approval-protocol',
  mainnet: 'metaphor-xyz/approval-protocol',
  ropsten: 'metaphor-xyz/approval-protocol-ropsten',
  polygon: 'metaphor-xyz/approval-protocol-polygon',
};

export function generateSupergraph(network: Network, ceramicUrl: string) {
  const ENS_REGISTRY_ADDRESS =
    network !== 'local' ? '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e' : '0x5587D8b9cd0C7b6Cd3a158EDC4e4D2aD0cd6e242';

  const ethersProvider = new EthersProvider({
    network,
    rpcTokens: { infura: '8c5c39d38539467bb5ac522203d758f2' },
    customMulticalls: {
      local: '0x2C78b7a68902864E85f8b8D72c9320aA5BF19f35',
    },
  });

  const didProvider = async () => {
    if (!ethersProvider.connected || !ethersProvider.mainAccount) {
      throw new Error('wallet not connected');
    }

    let storedSeed = window.localStorage.getItem(`metaphor-ceramic-seed/${ethersProvider.mainAccount}`);

    if (!storedSeed) {
      const signer = await ethersProvider.getSigner();

      if (!signer) {
        throw new Error('wallet signer unavailable');
      }

      const signature = await signer.signMessage('Allow this app to modify my applications to organizations');
      storedSeed = ethers.utils.keccak256(ethers.utils.defaultAbiCoder.encode(['string'], [signature]));

      window.localStorage.setItem(`metaphor-ceramic-seed/${ethersProvider.mainAccount}`, storedSeed);
    }

    return new Ed25519Provider(ethers.utils.arrayify(storedSeed));
  };

  const cache = new InMemoryCache();

  return {
    graph: new EnyoSupergraph({
      subgraphs: [
        new ERC20Subgraph(),
        new GraphProtocolSubgraph({
          subgraphName: APPROVAL_PROTOCOL_SUBGRAPH[network],
          customGraphURI: network === 'local' ? 'http://localhost:8000' : undefined,
          typeDefs: approvalProtocolSubgraph,
        }),
        new EthersWalletSubgraph(),
        new OrganizationsSubgraph({ network, customENSRegistryAddress: ENS_REGISTRY_ADDRESS }),
      ],
      providers: {
        ethers: ethersProvider,
        ceramic: new EnyoCeramicProvider({ apiURL: ceramicUrl, didProvider }),
        cache,
      },
    }),
    cache,
  };
}
