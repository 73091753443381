import { useEffect, useState } from 'react';

const KEY_PREFIX = 'ApplicationStatus:';
export type ApplicationStatus = [isPending: boolean, setIsPending: (isPending: boolean) => void];

export function createKey(transactionId: string, userId: string, committeeId: string) {
  return `${transactionId}-${userId}-${committeeId}`;
}

export default function useStoreApplicationStatus(key: string): ApplicationStatus {
  const fullKey = `${KEY_PREFIX}${key}`;
  const currentStatus = window.localStorage.getItem(fullKey);
  const [isPending, innerSetIsPending] = useState(currentStatus !== null);

  const setIsPending = (pending: boolean) => {
    if (pending) {
      window.localStorage.setItem(fullKey, 'PENDING');
      innerSetIsPending(true);
    } else {
      window.localStorage.removeItem(fullKey);
      innerSetIsPending(false);
    }
  };

  useEffect(() => {
    if (fullKey) {
      const status = window.localStorage.getItem(fullKey);
      innerSetIsPending(status !== null);
    }
  }, [fullKey]);

  return [isPending, setIsPending];
}
