import { ApolloClient } from '@apollo/client';

import { CERAMIC_URL, NETWORK } from './config';
import { generateSupergraph } from './supergraph';
import typeDefs from './typeDefs';

export const { graph, cache } = generateSupergraph(NETWORK, CERAMIC_URL);

export const client = new ApolloClient({
  cache,
  typeDefs: graph.typeDefs({ extraTypeDefs: typeDefs }),
  link: graph.link(),
});
