import { Typography, createStyles, OutlineWithSections } from '@metaphor-xyz/ui';
import React from 'react';
import { View } from 'react-native';

import { Organization } from '../../hooks/useOrganization';
import { DaoDetails } from './DaoDetails';

export default function SettingsSection({ organization }: { organization: Organization }) {
  const styles = useStyles();

  const items = [
    {
      outlineProps: { id: 'dao-details', label: 'DAO Details' },
      content: <DaoDetails organization={organization} />,
    },
    {
      outlineProps: { id: 'controller', label: 'Controller' },
      content: <></>,
    },
  ];

  return (
    <View style={styles.container}>
      <OutlineWithSections
        outlineStepperHeaderComponent={
          <Typography color="secondary-black" type="info" style={{ width: '100%' }}>
            Settings
          </Typography>
        }
        items={items}
        containerStyle={styles.outlineContainerStyle}
        contentStyle={styles.outlineContentStyle}
      />
    </View>
  );
}

const useStyles = createStyles(_theme => ({
  container: {
    width: '100%',
    alignItems: 'center',
    height: '100%',
  },
  outlineContainerStyle: { width: '100%', height: '100%' },
  outlineContentStyle: { flexGrow: 2, padding: 32 },
}));
