import { Typography, createStyles, OutlineWithSections } from '@metaphor-xyz/ui';
import React from 'react';
import { View } from 'react-native';

import {
  CommunityGuide,
  Discor101Guide,
  HowToIntroGuide,
  MembershipGuide,
  ProjectsGuide,
} from './CommunityGuidesSection';

export default function GuidesSection() {
  const styles = useStyles();

  const items = [
    {
      outlineProps: { id: 'community', label: 'Community' },
      content: <CommunityGuide />,
    },
    {
      outlineProps: { id: 'projects-and-comp', label: 'Projects & Comp' },
      content: <ProjectsGuide />,
    },
    {
      outlineProps: { id: 'how-to-intro', label: 'How to intro' },
      content: <HowToIntroGuide />,
    },
    {
      outlineProps: { id: 'discord-101', label: 'Discord 101' },
      content: <Discor101Guide />,
    },
    {
      outlineProps: { id: 'Membership', label: 'Membership' },
      content: <MembershipGuide />,
    },
  ];

  return (
    <View style={styles.container}>
      <OutlineWithSections
        outlineStepperHeaderComponent={
          <Typography color="secondary-black" type="info" style={{ width: '100%' }}>
            Guides
          </Typography>
        }
        items={items}
        containerStyle={styles.outlineContainerStyle}
        contentStyle={styles.outlineContentStyle}
      />
    </View>
  );
}

const useStyles = createStyles(_theme => ({
  container: {
    width: '100%',
    alignItems: 'center',
    height: '100%',
  },
  outlineContainerStyle: { width: '100%', height: '100%' },
  outlineContentStyle: { flexGrow: 2, padding: 32 },

  buttons: {
    flexDirection: 'row',
    display: 'flex',
    gap: 5,
  },
  questionAnswerRows: {
    width: '100%',
    paddingTop: 40,
    gap: 18,
  },
  questionAnswerRow: {
    minHeight: 20,
    flexDirection: 'row',
  },
  questionColumn: {
    width: 243,
  },
  answerColumn: {
    flexGrow: 2,
  },
}));
