import { NavigationProp, useNavigation as useNativeNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

export type StackParams = {
  home: undefined;
  explore: undefined;
  manager: undefined;
  profile: { ensNameOrAddress: string };

  overview: { orgEnsName: string };
  community: { orgEnsName: string };
  edit: { orgEnsName: string };
  onboard: { orgEnsName: string; membershipType?: string };
  setup: undefined;
};

const Stack = createStackNavigator<StackParams>();

export type OnboardingFlowStackParams = {
  connect: { orgEnsName: string; membershipType?: string };
  roles: { orgEnsName: string; membershipType?: string };
  approval: { orgEnsName: string; membershipType?: string };
  exchange: { orgEnsName: string; membershipType?: string };
  success: { orgEnsName: string; membershipType?: string };
};

export const OnboardingFlowStack = createStackNavigator<OnboardingFlowStackParams>();

export const useNavigation = () => useNativeNavigation<NavigationProp<StackParams>>();

export default Stack;
