import { Typography, createStyles, Button, OutlineWithSections, Loading, BannerAlert } from '@metaphor-xyz/ui';
import React, { useState, useCallback } from 'react';
import { View } from 'react-native';
import { Divider } from 'react-native-paper';

import type { ApprovalStatus } from '../../../__generated__/gqlTypes';
import { Organization } from '../../../hooks/useOrganization';
import useOrganizationApplications, {
  ApplicationApprove,
  ApplicationReject,
} from '../../../hooks/useOrganizationApplications';
import useStoreApplicationStatus, { createKey } from './useStoreApplicationStatus';

function clipWalletAddress(walletAddress: string) {
  return `${walletAddress.slice(0, 6)}...${walletAddress.slice(walletAddress.length - 4)}`;
}

function FormSection({
  id,
  committeeId,
  organization,
  userId,
  canModify,
  answers,
  approve,
  reject,
}: {
  id: string;
  committeeId: string;
  organization: Organization;
  userId: string;
  canModify: boolean;
  // eslint-disable-next-line
  answers: any;
  approve: ApplicationApprove;
  reject: ApplicationReject;
}) {
  const styles = useStyles();
  const fields = organization?.forms && organization.forms.length > 0 ? organization.forms[0].fields : [];
  const formattedAnswerMap = fields.map(field => ({ ...field, answer: answers[field.id] }));
  const [error, setError] = useState<undefined | string>();
  const [isPending, setIsPending] = useStoreApplicationStatus(createKey(id, committeeId, userId));

  const rejectApplication = useCallback(async () => {
    setIsPending(true);
    try {
      reject(id, committeeId);
    } catch (e) {
      setIsPending(false);
      setError('Error processing request, try again?');
    }
  }, [reject, setIsPending, setError, committeeId, id]);

  const approveApplication = useCallback(async () => {
    setIsPending(true);
    try {
      approve(id, committeeId);
    } catch (e) {
      setIsPending(false);
      setError('Error processing request, try again?');
    }
  }, [approve, setIsPending, setError, committeeId, id]);

  return (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
        <Typography type="h3">{clipWalletAddress(userId)}</Typography>
        {canModify && (
          <View style={styles.buttons}>
            <Button onPress={rejectApplication} title="Reject" color="secondary" disabled={isPending} />
            <Button title="Accept" color="primary" onPress={approveApplication} disabled={isPending} />
            <BannerAlert
              message={error}
              clearMessage={() => {
                setError(undefined);
              }}
            />
          </View>
        )}
      </View>

      <View style={styles.questionAnswerRows}>
        {formattedAnswerMap.map((answer, index) => (
          <View key={answer.id} style={{ width: '100%' }}>
            {index !== 0 && <Divider />}

            <View style={styles.questionAnswerRow}>
              <Typography color="secondary-black" type="info" style={styles.questionColumn}>
                {answer.label}
              </Typography>
              <Typography type="info" style={styles.answerColumn}>
                {answer.answer}
              </Typography>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

export default function ApprovalsSection({ organization }: { organization: Organization }) {
  const styles = useStyles();
  const {
    loading,
    approve,
    reject,
    applications: submitedApplications,
  } = useOrganizationApplications(organization.ensName, {
    status: 'IN_PROGRESS' as ApprovalStatus.InProgress,
  });

  if (loading || submitedApplications === null) {
    return <Loading />;
  }

  if (submitedApplications === null || !submitedApplications.length) {
    return (
      <View style={styles.container}>
        <Typography type="h3">No applications.</Typography>
      </View>
    );
  }

  const outlineItems = submitedApplications.map(submittedApp => {
    const { submitter, answers, requestId: id, committee, canModify } = submittedApp;
    return {
      outlineProps: {
        id: createKey(id, committee.committeeId, submitter.id),
        label: `${clipWalletAddress(submitter.id)}`,
        davatarAddress: submitter.id,
      },
      content: (
        <FormSection
          id={id}
          committeeId={committee.committeeId}
          organization={organization}
          userId={submitter.id}
          answers={answers.answers}
          canModify={canModify}
          approve={approve}
          reject={reject}
        />
      ),
    };
  });

  return (
    <View style={styles.container}>
      <OutlineWithSections
        outlineStepperHeaderComponent={
          <Typography color="secondary-black" type="info" style={{ width: '100%' }}>
            Pending approval
          </Typography>
        }
        items={outlineItems}
        containerStyle={styles.outlineContainerStyle}
        contentStyle={styles.outlineContentStyle}
      />
    </View>
  );
}

const useStyles = createStyles(_theme => ({
  container: {
    width: '100%',
    alignItems: 'center',
    height: '100%',
  },
  outlineContainerStyle: { width: '100%', height: '100%' },
  outlineContentStyle: { flexGrow: 2, padding: 32 },

  buttons: {
    flexDirection: 'row',
    display: 'flex',
    gap: 5,
  },
  questionAnswerRows: {
    width: '100%',
  },
  questionAnswerRow: {
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 20,
    gap: 16,
  },
  questionColumn: {
    width: 243,
  },
  answerColumn: {
    flexGrow: 1,
  },
}));
