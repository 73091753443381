import { useQuery, gql, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { OnboardingFlowQuery, OnboardingFlowQueryVariables } from '../__generated__/gqlTypes';

const ONBOARDING_FLOW = gql`
  query OnboardingFlow($ensName: String!) {
    onboardingFlow(ensName: $ensName) @client {
      chosenRoleId
      formData
    }
  }
`;

export default function useOnboardingFlow(ensName: string) {
  const client = useApolloClient();
  const { data, loading } = useQuery<OnboardingFlowQuery, OnboardingFlowQueryVariables>(ONBOARDING_FLOW, {
    variables: { ensName },
  });

  const setChosenRoleId = useCallback(
    (roleId: string | null) => {
      client.writeQuery({
        query: ONBOARDING_FLOW,
        data: { onboardingFlow: { chosenRoleId: roleId, formData: data?.onboardingFlow?.formData || null } },
        variables: { ensName },
      });
    },
    [client, ensName, data]
  );

  const setFormData = useCallback(
    (formData: Record<string, unknown>) => {
      client.writeQuery({
        query: ONBOARDING_FLOW,
        data: { onboardingFlow: { formData, chosenRoleId: data?.onboardingFlow?.chosenRoleId || null } },
        variables: { ensName },
      });
    },
    [client, ensName, data]
  );

  return {
    onboardingFlow: data?.onboardingFlow || null,
    loading,
    setChosenRoleId,
    setFormData,
  };
}
