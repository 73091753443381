import { useQuery, gql } from '@apollo/client';
import { useCeramic, useWalletManager } from '@metaphor-xyz/hooks';
import { ethers } from 'ethers';
import { useCallback, useEffect, useState } from 'react';

import { GetRoleRequestsQuery, GetRoleRequestsQueryVariables, Request_Filter } from '../__generated__/gqlTypes';
import type { FormSubmission } from './useApplication';
import useOrganization from './useOrganization';

const COMMITTEE_MANAGER_CONTRACT = '0x24afbdab603ffa376b722f18b67a957affa3ee13';
const COMMITTEE_ABI = ['function changeStatus(uint256,uint256,uint8) external'];

const QUERY = gql`
  query GetRoleRequests($committeeIds: [ID!]!, $filters: Request_filter) {
    committees(where: { id_in: $committeeIds }) {
      requests(where: $filters) {
        id
        requestId
        formSubmissionURI
        status

        committee {
          id
          committeeId
          metadataURI

          approver {
            id
          }
        }

        submitter {
          id
        }
      }
    }
  }
`;

/**
 * A super type of a Approval Protocol Request that hydrates the actual application form data
 * answers for the request, and whether or not the current connected wallet can approve/deny
 * the request.
 *
 */
export type Application = GetRoleRequestsQuery['committees'][0]['requests'][0] & {
  answers: FormSubmission;
  canModify: boolean;
};

// eslint-disable-next-line
export type ApplicationApprove = (applicationId: string, committeeId: string) => Promise<any>;
// eslint-disable-next-line
export type ApplicationReject = (applicationId: string, committeeId: string) => Promise<any>;

/**
 * Loads applications submitted to the committee specified by `committeeId`.
 *
 */
export default function useOrganizationApplications(orgEnsName: string, filters?: Request_Filter) {
  const [applications, setApplications] = useState<Application[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { loadStream } = useCeramic();
  const { organization, loading: orgLoading } = useOrganization(orgEnsName);
  const { getSigner, mainAccount } = useWalletManager();
  const { data } = useQuery<GetRoleRequestsQuery, GetRoleRequestsQueryVariables>(QUERY, {
    variables: {
      committeeIds: organization?.roles?.map(r => r.committeeId).filter(i => !!i) as string[],
      filters,
    },
    context: { subgraph: 'metaphor-xyz/approval-protocol' },
    skip: orgLoading || !organization,
  });

  useEffect(() => {
    if (data) {
      const loadedRequests = data.committees
        .map(c => c.requests)
        .flatMap(reqs =>
          reqs.map(r =>
            loadStream(r.formSubmissionURI).then(answers => ({
              ...r,
              answers: answers.content as FormSubmission,
              canModify: mainAccount?.toLowerCase() === r.committee.approver.id.toLowerCase(),
            }))
          )
        );

      Promise.all(loadedRequests).then(d => {
        setApplications(d);
        setLoading(false);
      });
    }
  }, [data, loadStream, mainAccount]);

  const approve = useCallback<ApplicationApprove>(
    async (applicationId, committeeId) => {
      const signer = await getSigner();

      if (!signer) {
        throw new Error('not connected to wallet');
      }

      const manager = new ethers.Contract(COMMITTEE_MANAGER_CONTRACT, COMMITTEE_ABI, signer);

      const tx = await manager.changeStatus(committeeId, applicationId, 2);

      return tx;
    },
    [getSigner]
  );

  const reject = useCallback<ApplicationReject>(
    async (applicationId, committeeId) => {
      const signer = await getSigner();

      if (!signer) {
        throw new Error('not connected to wallet');
      }

      const manager = new ethers.Contract(COMMITTEE_MANAGER_CONTRACT, COMMITTEE_ABI, signer);

      const tx = await manager.changeStatus(committeeId, applicationId, 1);

      return tx;
    },
    [getSigner]
  );

  return {
    approve,
    reject,
    loading,
    applications,
  };
}
