import { useWalletManager } from '@metaphor-xyz/hooks/dist';
import { PageContainer, Loading, NotFound, Takeover } from '@metaphor-xyz/ui';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { ActivityIndicator } from 'react-native-paper';

import TellUsAboutYourDao from '../components/SelfServeManagerSetup/TellUsAboutYourDao';
import { isDevManager } from '../config';
import useOrganization from '../hooks/useOrganization';
import { StackParams } from '../navigation';

export default function EditDaoScreen({ navigation, route }: StackScreenProps<StackParams, 'edit'>) {
  const { mainAccount, connecting } = useWalletManager();
  const { loading, organization } = useOrganization(route.params.orgEnsName);

  const onNavigate = useCallback(() => {
    navigation.navigate('home');
  }, [navigation]);

  if (loading) {
    return <Loading />;
  }

  if (!organization || !isDevManager(mainAccount)) {
    return <NotFound />;
  }

  if (connecting) {
    return (
      <PageContainer>
        <ActivityIndicator size="large" />
      </PageContainer>
    );
  }

  return (
    <Takeover header="DAO Profile">
      <TellUsAboutYourDao onNavigate={onNavigate} />
    </Takeover>
  );
}
