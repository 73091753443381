import { AntDesign } from '@expo/vector-icons';
import { Typography, createStyles, PageContainer, ThreeColumn, Button } from '@metaphor-xyz/ui';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useCallback } from 'react';
import { Image, Linking, View } from 'react-native';

import discordIconSrc from '../../assets/discordLogo.svg';
import welcomeImageSrc from '../../assets/welcome.png';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { OnboardingFlowStackParams, StackParams } from '../../navigation';
import { OnboardingFlowSidebar } from './OnboardingFlowSidebar';

const metaphorDiscordLink = 'https://discord.com/invite/aqvAHREdxT';

function SuccessMainComponent() {
  const styles = useStyles();
  const navigation = useNavigation();

  const onPressDiscord = useCallback(async () => {
    Linking.openURL(metaphorDiscordLink);
  }, []);

  const navigateToNextStep = useCallback(() => {
    // eslint-disable-next-line
    const nextStepId: any = 'community' as keyof StackParams & OnboardingFlowStackParams;
    navigation.navigate(nextStepId);
  }, [navigation]);

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image style={{ height: '100%' }} source={{ uri: welcomeImageSrc }} />
      </View>
      <Typography type="h3">Welcome to Brunch Club</Typography>

      <Typography type="body" color="secondary-black" style={styles.contentContainer}>
        You’re all setup to join in!
      </Typography>

      <Button
        onPress={navigateToNextStep}
        title="Read how to get involved"
        postTextComponent={<AntDesign style={styles.buttonIcon} name="arrowright" size={20} />}
      />
      <View style={styles.contentContainer}>
        <Button
          color="secondary"
          onPress={onPressDiscord}
          title="Skip, jump to Discord"
          postTextComponent={
            <View style={{ marginLeft: 34, height: 21, width: 21 }}>
              <Image style={{ height: '100%' }} source={{ uri: discordIconSrc }} />
            </View>
          }
        />
      </View>
    </View>
  );
}

export default function SuccessStep() {
  const breakpoint = useBreakpoint();

  return (
    <PageContainer>
      <ThreeColumn
        leftComponent={breakpoint === 'xs' || breakpoint === 'sm' ? undefined : <OnboardingFlowSidebar />}
        middleComponent={<SuccessMainComponent />}
      />
    </PageContainer>
  );
}

const useStyles = createStyles(_theme => ({
  container: {
    alignItems: 'center',
  },
  contentContainer: {
    marginTop: 16,
    marginBottom: 22,
  },
  imageContainer: {
    width: 306,
    height: 166,
    marginBottom: 32,
  },
  buttonIcon: {
    marginLeft: 8,
    color: 'white',
  },
}));
