import {
  Breadcrumbs,
  BreadcrumbsItem,
  PageContainer,
  SimpleVerticalStepper,
  SimpleVerticalStepperItem,
  ThreeColumn,
} from '@metaphor-xyz/ui';
import React, { useState } from 'react';
import { View } from 'react-native';

import ConnectEns from '../components/SelfServeManagerSetup/ConnectENS';
import DaoStructure from '../components/SelfServeManagerSetup/DaoStructure';
import { EnsInfo } from '../components/SelfServeManagerSetup/EnsInfo';

export default function AddEnsScreen() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentStep, setCurrentStep] = useState('dao-structure');

  return (
    <PageContainer>
      <ThreeColumn
        leftComponent={
          <View>
            <Breadcrumbs activeItem="setup">
              <BreadcrumbsItem id="add-a-dao" label="Add a DAO" />
              <BreadcrumbsItem id="setup" label="Setup" />
            </Breadcrumbs>
            <View style={{ marginTop: 40 }}>
              <SimpleVerticalStepper activeItem={currentStep}>
                <SimpleVerticalStepperItem key="connect-ens" id="connect-ens" label="Connect ENS" />
                <SimpleVerticalStepperItem key="dao-structure" id="dao-structure" label="DAO Structure" />
              </SimpleVerticalStepper>
            </View>
          </View>
        }
        middleComponent={<>{currentStep === 'connect-ens' ? <ConnectEns /> : <DaoStructure />}</>}
        rightComponent={<>{currentStep === 'connect-ens' ? <EnsInfo /> : null}</>}
      />
    </PageContainer>
  );
}
