import { AntDesign } from '@expo/vector-icons';
import { useWalletManager } from '@metaphor-xyz/hooks/dist';
import {
  Typography,
  createStyles,
  ActionCard,
  ActionCardGroup,
  Pill,
  InfoColumn,
  PageContainer,
  ThreeColumn,
  Form,
  Button,
  Loading,
  NotFound,
} from '@metaphor-xyz/ui';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { View } from 'react-native';

import pancakesSrc from '../../assets/brunchclub/pancakes.svg';
import goals from '../../assets/icons/light_mode_icons/goals.svg';
import useApplications from '../../hooks/useApplications';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import useOnboardingFlow from '../../hooks/useOnboardingFlow';
import useOrganization from '../../hooks/useOrganization';
import { OnboardingFlowStackParams, StackParams } from '../../navigation';
import { OnboardingFlowSidebar } from './OnboardingFlowSidebar';

export function ChoosePositionStepInfo() {
  return (
    <InfoColumn
      icon={goals}
      description="A crypto wallet is an application or hardware device that allows individuals to store and retrieve digital items."
      accordianTips={[
        {
          title: 'What’s a DAO?',
          description:
            'An online community with a bank account and shared mission with rules on how decisions are made.',
        },
        {
          title: 'What’s a POAP?',
          description:
            'POAPs (Proof Of Attendance Protocol) are exclusive Ethereum-based NFT badges given to you for participating or attending special events. The events can be in the physical world, online, or in the metaverse.',
        },
        {
          title: 'What’s a $BRUNCH?',
          description:
            '$BRUNCH is the community token associated with the Brunch Club community. Just like crypto currency it’s value will fluctuate with the community success and market.',
        },
      ]}
    />
  );
}

function ChoosePositionMainComponent({
  orgEnsName,
  initialMembershipType,
}: {
  orgEnsName: string;
  initialMembershipType?: string;
}) {
  const styles = useStyles();
  const { loading, organization } = useOrganization(orgEnsName);
  const { onboardingFlow, setChosenRoleId: setSelectedRoleId } = useOnboardingFlow(orgEnsName);
  const { mainAccount } = useWalletManager();
  const { submitApplication } = useApplications(mainAccount || '');

  useEffect(() => {
    if (initialMembershipType) {
      setSelectedRoleId(initialMembershipType);
    }
  }, [initialMembershipType, setSelectedRoleId]);

  const selectedRoleId = onboardingFlow?.chosenRoleId;

  const navigation = useNavigation();
  const navigateToNextStep = useCallback(() => {
    // eslint-disable-next-line
    const nextStepId: any = 'approval' as keyof StackParams & OnboardingFlowStackParams;
    navigation.navigate(nextStepId);
  }, [navigation]);

  const onPressPosition = useCallback(
    (newPosition: string) => () => {
      if (newPosition === selectedRoleId) {
        setSelectedRoleId(null);
      } else {
        setSelectedRoleId(newPosition);
      }
    },
    [selectedRoleId, setSelectedRoleId]
  );

  if (loading) {
    return <Loading />;
  }

  if (!organization) {
    return <NotFound />;
  }

  const selectedRole = (organization.roles || []).find(r => r.id === selectedRoleId);
  const selectedRoleForm = organization.forms?.find(form => selectedRole?.formId === form.id);

  // eslint-disable-next-line
  const onSubmit = async (data: any) => {
    if (selectedRole && selectedRole.committeeId && selectedRole.formId) {
      await submitApplication(selectedRole.committeeId, 'ceramic://replace', selectedRole.formId, data);
      navigateToNextStep();
    }
  };

  return (
    <View style={styles.container}>
      <Typography type="h3">Choose role</Typography>
      <Typography type="body" color="secondary-black" style={styles.contentContainer}>
        Many DAO’s will offer different positions that match different ways in which members may want to interact,
        choose the best that describes you.
      </Typography>
      <ActionCardGroup>
        {(organization.roles || []).map(role => {
          const tokenString = (role.requirements || []).map(req => `${req.minBalance} ${req.name}`).join(' + ');

          return (
            <ActionCard
              key={role.id}
              title={role.name}
              description={role.description || undefined}
              icon={role.icon || pancakesSrc}
              onPress={onPressPosition(role.id)}
              reduceOpacity={selectedRoleId !== undefined && selectedRoleId !== null && selectedRoleId !== role.id}
              postTextComponent={
                <View style={{ alignItems: 'center' }}>
                  <Typography style={{ marginBottom: 10 }} type="info">
                    {tokenString}
                  </Typography>
                  {role.memberCount && (
                    <Pill
                      iconComponent={
                        <>
                          <AntDesign
                            style={{ borderRadius: 100, height: '14px', width: '14px', marginRight: '4px' }}
                            name="user"
                            size={14}
                            color="#706B67"
                          />
                        </>
                      }
                      text={`${role.memberCount}`}
                    />
                  )}
                </View>
              }
            />
          );
        })}
      </ActionCardGroup>

      {selectedRoleId && selectedRole && selectedRoleForm && (
        <View>
          <Typography type="h3" style={styles.roleFormContainer}>
            {selectedRole.name} details
          </Typography>

          <Form defaultValues={{}} form={selectedRoleForm} onSubmit={onSubmit} />
        </View>
      )}
      {selectedRoleId && selectedRole && !selectedRoleForm && (
        <View style={styles.roleFormContainer}>
          <Button
            onPress={navigateToNextStep}
            title="Submit"
            postTextComponent={<AntDesign style={styles.buttonIcon} name="arrowright" size={20} />}
          />
        </View>
      )}
    </View>
  );
}

export default function ChoosePositionStep({ route }: StackScreenProps<OnboardingFlowStackParams, 'roles'>) {
  const initialMembershipType = route.params?.membershipType;
  const orgEnsName = route.params.orgEnsName;
  const breakpoint = useBreakpoint();

  return (
    <PageContainer>
      <ThreeColumn
        leftComponent={breakpoint === 'xs' || breakpoint === 'sm' ? undefined : <OnboardingFlowSidebar />}
        middleComponent={
          <ChoosePositionMainComponent orgEnsName={orgEnsName} initialMembershipType={initialMembershipType} />
        }
        rightComponent={<ChoosePositionStepInfo />}
      />
    </PageContainer>
  );
}

const useStyles = createStyles(_theme => ({
  container: {
    paddingTop: 8,
    paddingBottom: 56,
  },
  contentContainer: {
    marginTop: 24,
    marginBottom: 32,
  },
  roleFormContainer: {
    marginTop: 56,
    marginBottom: 32,
  },
  input: {
    marginBottom: 28,
  },
  buttonIcon: {
    marginLeft: 8,
    color: 'white',
  },
}));
