import { useQuery, gql } from '@apollo/client';
import { useState, useEffect } from 'react';

import { NETWORK } from '../config';

export const ENS_REGISTRY_ADDRESS =
  NETWORK !== 'local' && !!NETWORK
    ? '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
    : '0x5587D8b9cd0C7b6Cd3a158EDC4e4D2aD0cd6e242';

export type OrganizationFormFieldType = 'text' | 'email' | 'phone_number' | 'address' | 'select' | 'number';

export type OrganizationFormFieldSelectChoicesType = { label: string; id: string };

export type OrganizationFormFieldConfig =
  | {
      type: 'text' | 'email' | 'phone_number' | 'address';
    }
  | {
      type: 'select';
      options: {
        choices: OrganizationFormFieldSelectChoicesType[];
        multiple: boolean | null;
      };
    }
  | {
      type: 'number';
      options: {
        range: { start: number | null; end: number | null } | null;
        units: string | null;
      } | null;
    };

/**
 * A field in a form used to apply for a role in an organization.
 *
 */
export type OrganizationFormField = {
  id: string;
  type: OrganizationFormFieldType;
  label: string;
  sub_label: string | null;
  required: boolean | null;
} & OrganizationFormFieldConfig;

/**
 * A form used to apply for one or more roles within an organization.
 *
 */
export interface OrganizationForm {
  id: string;
  reference: string;
  name: string | null;
  description: string | null;
  fields: OrganizationFormField[];
}

export type OrganizationFormReference = OrganizationForm;

export type RoleRequirement = { contractId: string; minBalance: number; name: string | null } & (
  | { type: 'erc20' | 'erc721' }
  | { type: 'erc1155'; tokenId: string | null }
);

/**
 * A role defined by the organization, possibly including an application in order to apply for the role.
 *
 */
export interface OrganizationRole {
  id: string;
  name: string;
  icon: string | null;
  description: string | null;
  descriptionLong: string | null;
  requirements: RoleRequirement[] | null;
  formId: string | null;
  committeeId: string | null;
  memberCount: number | null;
}

export type OrganizationRoleReference = OrganizationRole | { id: string; reference: string };

export type OrganizationImages = { logo: string | null; banner: string | null };

/**
 * A Metaphor-spec organization profile.
 *
 */
export interface Organization {
  name: string;
  ensName: string;
  description: string;
  descriptionLong: string | null;
  discordUrl: string | null;
  forms: OrganizationFormReference[] | null;
  roles: OrganizationRole[] | null;
  images: OrganizationImages | null;
}

const GET_ORGANIZATION = gql`
  query GetOrganization($ensName: String!) {
    organization(ensName: $ensName) {
      id
      name
      ensName
      description
      discord
      avatar
      banner

      forms {
        id
        reference
        name
        description

        fields {
          id
          type
          label
          subLabel
          required
        }
      }

      roles {
        id
        name
        icon
        description
        descriptionLong
        requirements
        formId
        committeeId
        memberCount
      }
    }
  }
`;

/**
 * Loads a Metaphor Organization from an ENS name.
 *
 */
export default function useOrganization(ensName: string) {
  const [organization, setOrganization] = useState<Organization | null>(null);
  const { data, loading } = useQuery(GET_ORGANIZATION, { variables: { ensName } });

  useEffect(() => {
    if (data) {
      setOrganization({
        name: data.organization.name,
        ensName: data.organization.ensName,
        description: data.organization.description,
        descriptionLong: data.organization.description,
        discordUrl: data.organization.discord,
        forms: data.organization.forms,
        roles: data.organization.roles,
        images: {
          logo: data.organization.avatar,
          banner: data.organization.banner,
        },
      });
    }
  }, [data]);

  return { organization, loading };
}
