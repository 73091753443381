import { createStyles, Breadcrumbs, BreadcrumbsItem, Loading } from '@metaphor-xyz/ui';
import React from 'react';
import { View } from 'react-native';

import { useBreakpoint } from '../hooks/useBreakpoint';
import useOrganization from '../hooks/useOrganization';

export default function BreadcrumbsView({ orgEnsName }: { orgEnsName: string }) {
  const styles = useStyles();
  const breakpoint = useBreakpoint();

  const { loading, organization } = useOrganization(orgEnsName);

  if (!organization || loading) {
    return (
      <View
        style={[
          styles.breadcrumbContainer,
          (breakpoint === 'xs' || breakpoint === 'sm') && styles.breadcrumbContainerSm,
        ]}
      >
        {loading && <Loading />}
      </View>
    );
  }

  const logo = organization.images?.logo;

  return (
    <View
      style={[styles.breadcrumbContainer, (breakpoint === 'xs' || breakpoint === 'sm') && styles.breadcrumbContainerSm]}
    >
      <Breadcrumbs activeItem="getting-started">
        <BreadcrumbsItem id="top" label="Brunch Club" icon={logo || undefined} />
        <BreadcrumbsItem id="getting-started" label="Getting Started" />
      </Breadcrumbs>
    </View>
  );
}

const useStyles = createStyles(() => ({
  breadcrumbContainer: { paddingRight: 32, paddingLeft: 32, paddingTop: 16, paddingBottom: 0 },
  breadcrumbContainerSm: { paddingRight: 24, paddingLeft: 24 },
}));
