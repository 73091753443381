import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const window = Dimensions.get('window');

const getBreakpoint = (width: number) => {
  if (!width) return 'md';

  if (width <= 600) {
    return 'xs';
  } else if (width > 600 && width <= 900) {
    return 'sm';
  } else if (width > 900 && width <= 1100) {
    return 'md';
  } else if (width > 1100 && width <= 1536) {
    return 'lg';
  } else {
    return 'xl';
  }
};

export function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState<Breakpoint | undefined>(getBreakpoint(window.width));

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window: newWindow }) => {
      const newBreakpoint = getBreakpoint(newWindow.width);
      if (newBreakpoint != breakpoint) {
        setBreakpoint(newBreakpoint);
      }
    });
    // @ts-ignore
    return () => subscription?.remove();
  }, [breakpoint]);

  return breakpoint;
}
