import { ApolloProvider } from '@apollo/client';
import { CeramicProviderWithWallet } from '@metaphor-xyz/hooks';
import { ThemeProvider, BaseContainer, createStyles } from '@metaphor-xyz/ui';
import { NavigationContainer } from '@react-navigation/native';
import { registerRootComponent } from 'expo';
import React from 'react';

import { CERAMIC_URL } from './config';
import { client } from './graph';
import Header from './headers/Header';
import Stack from './navigation';
import AddEnsScreen from './screens/AddEnsScreen';
import EditDaoScreen from './screens/EditDaoScreen';
import ExploreScreen from './screens/ExploreScreen';
import HomeScreen from './screens/HomeScreen';
import ManagerScreen from './screens/ManagerScreen';
import OnboardingNavigation from './screens/OnboardingNavigation';
import OverviewForDAO from './screens/OverviewForDAO';

const linking = {
  prefixes: [],
  config: {
    screens: {
      home: '/',
      explore: '/explore',
      manager: '/manager',
      profile: '/users/:ensNameOrAddress',

      // Community pages
      overview: '/:orgEnsName',
      community: '/:orgEnsName/community',
      edit: '/:orgEnsName/edit',

      // Community onboarding flow
      onboard: '/:orgEnsName/onboard',
      connect: '/connect',
      roles: '/choose-position',
      approval: '/approval',
      exchange: '/exchange',
      success: '/success',

      // Self-serve manager setup
      setup: '/setup',
    },
  },
};

function Navigation() {
  const styles = useStyles();

  return (
    <Stack.Navigator
      screenOptions={{
        title: 'Metaphor',
        header: Header,
        cardStyle: styles.cardStyle,
      }}
    >
      <Stack.Screen name="home" component={HomeScreen} />
      <Stack.Screen name="explore" component={ExploreScreen} />
      <Stack.Screen name="community" component={ManagerScreen} initialParams={{ orgEnsName: 'brunchclub.eth' }} />
      <Stack.Screen name="overview" component={OverviewForDAO} initialParams={{ orgEnsName: 'brunchclub.eth' }} />
      <Stack.Screen name="onboard" component={OnboardingNavigation} initialParams={{ orgEnsName: 'brunchclub.eth' }} />
      <Stack.Screen name="setup" component={AddEnsScreen} />
      <Stack.Screen name="edit" component={EditDaoScreen} />
    </Stack.Navigator>
  );
}

function App() {
  return (
    <ThemeProvider>
      <ApolloProvider client={client}>
        <NavigationContainer linking={linking}>
          <CeramicProviderWithWallet apiUrl={CERAMIC_URL}>
            <BaseContainer>
              <Navigation />
            </BaseContainer>
          </CeramicProviderWithWallet>
        </NavigationContainer>
      </ApolloProvider>
    </ThemeProvider>
  );
}

const useStyles = createStyles(theme => ({
  cardStyle: {
    backgroundColor: theme.colors.background,
  },
}));

export default registerRootComponent(App);
