import { createStyles, InfoCard, InfoCardGroup, Typography } from '@metaphor-xyz/ui/dist';
import React from 'react';
import { View } from 'react-native';

import communityTokenSvg from '../../assets/icons/light_mode_icons/communitytoken.svg';
import cryptoCurrencySvg from '../../assets/icons/light_mode_icons/cryptocurrency.svg';
import gasFeesSvg from '../../assets/icons/light_mode_icons/gasfees.svg';
import goalsSvg from '../../assets/icons/light_mode_icons/goals.svg';
import walletSvg from '../../assets/icons/light_mode_icons/wallet.svg';

export default function InfoCardsSection() {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Typography type="h2" style={styles.title}>
        What you'll need
      </Typography>
      <InfoCardGroup>
        <InfoCard
          title="Crypto Currency"
          description="Typically, you’ll need to swap crypto currency to get a DAO token. Ethereum is a good place to start as it is a compatible swap for most tokens. Check out Coinbase or Rainbow to get started."
          icon={cryptoCurrencySvg}
        />
        <InfoCard
          title="Crypto Wallet"
          description="Once you have funds, you’ll need a browser compatible wallet to connect your profile to the DAO. This ‘wallet’ will be used to ‘login’ and purchase tokens. We’re using Metamask but support many."
          icon={walletSvg}
        />
        <InfoCard
          title="Understand Gas Fees"
          description="Gas fee’s is a charge to interact and write to the blockchain network. These fee’s occur when swapping tokens and are dynamic based on the network activity. Simple connecting doesn’t incur these fee’s."
          icon={gasFeesSvg}
        />
        <InfoCard
          title="Your goals"
          description="Decide on what your goals are, is it to earn through contribution or supporting a community through investing funds. Setting your goals and time commitment will ensure you’re choosing the right membership."
          icon={goalsSvg}
        />
        <InfoCard
          title="A Community Token"
          description="In most cases you’ll need to purchase the DAO token to unlock access to the community. We make it easy to do this swap. You’ll just need to choose which currency you want to swap from."
          icon={communityTokenSvg}
        />
      </InfoCardGroup>
    </View>
  );
}

const useStyles = createStyles(_theme => ({
  container: { marginTop: 72, alignItems: 'center' },
  title: {
    marginBottom: 52,
  },
}));
