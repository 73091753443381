import { AntDesign } from '@expo/vector-icons';
import { Typography, createStyles, Button } from '@metaphor-xyz/ui';
import Clipboard from '@react-native-clipboard/clipboard';
import React, { useCallback } from 'react';
import { Linking, View, Image, TouchableOpacity } from 'react-native';

import discordIconSrc from '../../assets/discordLogo.svg';
import { Organization } from '../../hooks/useOrganization';

export default function HomeSection({ organization }: { organization: Organization }) {
  const styles = useStyles();

  const onPressDiscord = useCallback(async () => {
    if (organization.discordUrl) {
      await Linking.openURL(organization.discordUrl);
    }
  }, [organization]);

  const onPressOnboardingLink = useCallback(async () => {
    Clipboard.setString(`https://${window.location.host}/${organization.ensName}`);
  }, [organization.ensName]);

  const fakeActionItems = [
    'Read the community details',
    'Introduct yourself',
    'Join a Townhall',
    'Pick up a bounty project',
    'Refer someone',
  ];

  return (
    <View style={styles.container}>
      <View style={{ flexGrow: 2, padding: 32 }}>
        <View style={styles.headerContainer}>
          <Typography type="h2">Home</Typography>

          <TouchableOpacity onPress={onPressOnboardingLink} activeOpacity={0.8} style={styles.linkContainer}>
            <AntDesign style={styles.linkIcon} name="link" size={14} color="#706B67" />
            <Typography
              type="info"
              color="secondary-black"
            >{`${window.location.host}/${organization.ensName}`}</Typography>
          </TouchableOpacity>
        </View>

        <View style={styles.actionRowsContainer}>
          {fakeActionItems.map((actionItem, i) => (
            <View key={i} style={styles.actionRow}>
              <View style={styles.fakeCheckbox} />
              <Typography>{actionItem}</Typography>
            </View>
          ))}
        </View>

        <Typography type="h3" color="secondary-black" style={{ marginTop: 24 }}>
          Community dashboard coming soon!
        </Typography>
      </View>
      <View style={styles.rightColumn}>
        {organization.discordUrl ? (
          <Button
            color="secondary"
            onPress={onPressDiscord}
            title="Open Discord"
            postTextComponent={
              <View style={styles.discordButtonIcon}>
                <Image style={{ height: '100%' }} source={{ uri: discordIconSrc }} />
              </View>
            }
          />
        ) : null}
      </View>
    </View>
  );
}

const useStyles = createStyles(theme => ({
  container: {
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 16,
    gap: 24,
  },
  linkContainer: { flexDirection: 'row', gap: 4, alignItems: 'center' },
  linkIcon: { borderRadius: 100, height: '14px', width: '14px' },

  actionRowsContainer: {
    display: 'flex',
    gap: 1,
  },
  actionRow: {
    backgroundColor: theme.colors.primary,
    borderRadius: 2,
    flexDirection: 'row',
    paddingRight: 24,
    paddingLeft: 24,
    paddingTop: 12,
    paddingBottom: 12,
    alignItems: 'center',
  },
  fakeCheckbox: {
    width: 30,
    height: 30,
    borderRadius: 2,
    backgroundColor: theme.colors.primaryWhite,
    marginRight: 24,
    border: `1px solid ${theme.colors.outlineGray}`,
    cursor: 'pointer',
  },

  rightColumn: { width: 250, alignItems: 'flex-end' },
  discordButtonIcon: { marginLeft: 34, height: 21, width: 21 },
}));
