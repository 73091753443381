import { ThreeColumn, Typography, PageContainer, OrgProfileCard, Loading } from '@metaphor-xyz/ui';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { View } from 'react-native';

import bloodyMarySrc from '../assets/brunchclub/bloody_mary.svg';
import useOrganization from '../hooks/useOrganization';
import { StackParams } from '../navigation';

export default function ExploreScreen({ navigation }: StackScreenProps<StackParams, 'explore'>) {
  const onNavigate = useCallback(
    (orgEnsName: string) => () => {
      navigation.navigate('overview', {
        orgEnsName,
      });
    },
    [navigation]
  );

  // TODO: Replace with organizations
  const { loading, organization } = useOrganization('brunchclub.eth');
  const orgs = organization ? [organization] : [];

  if (loading) {
    return (
      <PageContainer>
        <Loading size="large" />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ThreeColumn
        middleComponent={
          <View>
            <Typography type="h2">Explore DAOs!</Typography>

            {orgs.map(org => (
              <OrgProfileCard
                key={org.ensName}
                title={org.name}
                imageUri={org?.images?.logo || bloodyMarySrc}
                onPress={onNavigate(org.ensName)}
                // TODO: Replace with real description & button when not demo
                description="This is a DAO the Metaphor team has created in order to showcase their product."
                buttonTitle="Check out the demo"
              />
            ))}
          </View>
        }
      />
    </PageContainer>
  );
}
