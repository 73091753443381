import { Network } from '@metaphor-xyz/hooks';
import Constants from 'expo-constants';

export const CERAMIC_URL = Constants.manifest?.extra?.ceramicUrl || 'http://localhost:7007';
export const NETWORK = (Constants.manifest?.extra?.network || 'local') as Network;

export const isDevManager = (mainAccount: string | null): boolean => {
  return (
    mainAccount === '0xEE599e7c078cAdBB7f81531322d6534F22749136' ||
    mainAccount === '0x9B6568d72A6f6269049Fac3998d1fadf1E6263cc' ||
    mainAccount === '0x7D591Fb3994dCd8Bf73978c87a041EEF1339DC92' || // Julia
    mainAccount === '0xe437144d0146dee09a3e99541d77c4fcee2dff40' ||
    mainAccount === '0xaf5444e2bdd7e7cBb8210dDF1357460D2fb489ed' // Markus
  );
};
