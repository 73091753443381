import { createStyles, Typography } from '@metaphor-xyz/ui';
import React from 'react';
import { View, Image } from 'react-native';

import { Organization } from '../../hooks/useOrganization';

export function DaoDetails({ organization }: { organization: Organization }) {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Typography type="h3">DAO Details</Typography>
      <View>
        <Typography style={styles.label} type="body-bold">
          DAO Name
        </Typography>
        <Typography type="body">{organization.name}</Typography>
      </View>
      <View>
        <Typography style={styles.label} type="body-bold">
          DAO Description
        </Typography>
        <Typography type="body">{organization.descriptionLong || ''}</Typography>
      </View>
      <View>
        <Typography style={styles.label} type="body-bold">
          Description
        </Typography>
        <Typography type="body">{organization.description}</Typography>
      </View>
      <View style={styles.row}>
        <View>
          <Typography style={styles.label} type="body-bold">
            Background Image
          </Typography>
          <Image style={{ width: 80, height: 80 }} source={{ uri: organization.images?.banner || '' }} />
        </View>
        <View>
          <Typography style={styles.label} type="body-bold">
            Icon
          </Typography>
          <Image style={{ width: 30, height: 30 }} source={{ uri: organization.images?.logo || '' }} />
        </View>
      </View>
    </View>
  );
}

const useStyles = createStyles(_theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 32,
  },
  label: {
    marginBottom: 8,
  },
}));
