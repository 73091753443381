import { gql } from '@apollo/client';

export default gql`
  directive @entity on OBJECT

  directive @derivedFrom(field: String) on FIELD_DEFINITION

  directive @subgraphId(id: String) on OBJECT

  type _Block_ {
    hash: Bytes

    number: Int!
  }

  type _Meta_ {
    block: _Block_!

    deployment: String!

    hasIndexingErrors: Boolean!
  }

  enum _SubgraphErrorPolicy_ {
    allow
    deny
  }

  type Account {
    id: ID!
    requests(
      skip: Int = 0
      first: Int = 100
      orderBy: Request_orderBy
      orderDirection: OrderDirection
      where: Request_filter
    ): [Request!]!
  }

  input Account_filter {
    id: ID
    id_not: ID
    id_gt: ID
    id_lt: ID
    id_gte: ID
    id_lte: ID
    id_in: [ID!]
    id_not_in: [ID!]
  }

  enum Account_orderBy {
    id
    requests
  }

  enum ApprovalStatus {
    IN_PROGRESS
    REJECTED
    APPROVED
  }

  scalar BigDecimal

  scalar BigInt

  input Block_height {
    hash: Bytes
    number: Int
    number_gte: Int
  }

  scalar Bytes

  type Committee {
    id: ID!
    committeeId: BigInt!
    owner: Account!
    approver: Account!
    metadataURI: String!
    requests(
      skip: Int = 0
      first: Int = 100
      orderBy: Request_orderBy
      orderDirection: OrderDirection
      where: Request_filter
    ): [Request!]!
  }

  input Committee_filter {
    id: ID
    id_not: ID
    id_gt: ID
    id_lt: ID
    id_gte: ID
    id_lte: ID
    id_in: [ID!]
    id_not_in: [ID!]
    owner: String
    owner_not: String
    owner_gt: String
    owner_lt: String
    owner_gte: String
    owner_lte: String
    owner_in: [String!]
    owner_not_in: [String!]
    owner_contains: String
    owner_not_contains: String
    owner_starts_with: String
    owner_not_starts_with: String
    owner_ends_with: String
    owner_not_ends_with: String
    approver: String
    approver_not: String
    approver_gt: String
    approver_lt: String
    approver_gte: String
    approver_lte: String
    approver_in: [String!]
    approver_not_in: [String!]
    approver_contains: String
    approver_not_contains: String
    approver_starts_with: String
    approver_not_starts_with: String
    approver_ends_with: String
    approver_not_ends_with: String
    metadataURI: String
    metadataURI_not: String
    metadataURI_gt: String
    metadataURI_lt: String
    metadataURI_gte: String
    metadataURI_lte: String
    metadataURI_in: [String!]
    metadataURI_not_in: [String!]
    metadataURI_contains: String
    metadataURI_not_contains: String
    metadataURI_starts_with: String
    metadataURI_not_starts_with: String
    metadataURI_ends_with: String
    metadataURI_not_ends_with: String
  }

  enum Committee_orderBy {
    id
    owner
    approver
    metadataURI
    requests
  }

  enum OrderDirection {
    asc
    desc
  }

  type Query {
    account(
      id: ID!

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): Account
    accounts(
      skip: Int = 0
      first: Int = 100
      orderBy: Account_orderBy
      orderDirection: OrderDirection
      where: Account_filter

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): [Account!]!
    committee(
      id: ID!

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): Committee
    committees(
      skip: Int = 0
      first: Int = 100
      orderBy: Committee_orderBy
      orderDirection: OrderDirection
      where: Committee_filter

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): [Committee!]!
    request(
      id: ID!

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): Request
    requests(
      skip: Int = 0
      first: Int = 100
      orderBy: Request_orderBy
      orderDirection: OrderDirection
      where: Request_filter

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): [Request!]!

    _meta(block: Block_height): _Meta_
  }

  type Request {
    id: ID!
    committee: Committee!
    requestId: BigInt!
    submitter: Account!
    formSubmissionURI: String!
    status: ApprovalStatus!
  }

  input Request_filter {
    id: ID
    id_not: ID
    id_gt: ID
    id_lt: ID
    id_gte: ID
    id_lte: ID
    id_in: [ID!]
    id_not_in: [ID!]
    committee: String
    committee_not: String
    committee_gt: String
    committee_lt: String
    committee_gte: String
    committee_lte: String
    committee_in: [String!]
    committee_not_in: [String!]
    committee_contains: String
    committee_not_contains: String
    committee_starts_with: String
    committee_not_starts_with: String
    committee_ends_with: String
    committee_not_ends_with: String
    requestId: BigInt
    requestId_not: BigInt
    requestId_gt: BigInt
    requestId_lt: BigInt
    requestId_gte: BigInt
    requestId_lte: BigInt
    requestId_in: [BigInt!]
    requestId_not_in: [BigInt!]
    submitter: String
    submitter_not: String
    submitter_gt: String
    submitter_lt: String
    submitter_gte: String
    submitter_lte: String
    submitter_in: [String!]
    submitter_not_in: [String!]
    submitter_contains: String
    submitter_not_contains: String
    submitter_starts_with: String
    submitter_not_starts_with: String
    submitter_ends_with: String
    submitter_not_ends_with: String
    formSubmissionURI: String
    formSubmissionURI_not: String
    formSubmissionURI_gt: String
    formSubmissionURI_lt: String
    formSubmissionURI_gte: String
    formSubmissionURI_lte: String
    formSubmissionURI_in: [String!]
    formSubmissionURI_not_in: [String!]
    formSubmissionURI_contains: String
    formSubmissionURI_not_contains: String
    formSubmissionURI_starts_with: String
    formSubmissionURI_not_starts_with: String
    formSubmissionURI_ends_with: String
    formSubmissionURI_not_ends_with: String
    status: ApprovalStatus
    status_not: ApprovalStatus
    status_in: [ApprovalStatus!]
    status_not_in: [ApprovalStatus!]
  }

  enum Request_orderBy {
    id
    committee
    requestId
    submitter
    formSubmissionURI
    status
  }

  type Subscription {
    account(
      id: ID!

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): Account
    accounts(
      skip: Int = 0
      first: Int = 100
      orderBy: Account_orderBy
      orderDirection: OrderDirection
      where: Account_filter

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): [Account!]!
    committee(
      id: ID!

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): Committee
    committees(
      skip: Int = 0
      first: Int = 100
      orderBy: Committee_orderBy
      orderDirection: OrderDirection
      where: Committee_filter

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): [Committee!]!
    request(
      id: ID!

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): Request
    requests(
      skip: Int = 0
      first: Int = 100
      orderBy: Request_orderBy
      orderDirection: OrderDirection
      where: Request_filter

      block: Block_height

      subgraphError: _SubgraphErrorPolicy_! = deny
    ): [Request!]!

    _meta(block: Block_height): _Meta_
  }
`;
