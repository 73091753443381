import { gql } from '@apollo/client';

const localTypes = gql`
  extend type Query {
    onboardingFlow(ensName: String!): OnboardingFlowState
  }

  type OnboardingFlowState {
    chosenRoleId: String
    formData: FormData
  }

  scalar FormData
`;

export default localTypes;
