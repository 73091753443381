import { InfoColumn } from '@metaphor-xyz/ui';
import React from 'react';

import goals from '../../assets/icons/light_mode_icons/goals.svg';

export function EnsInfo() {
  return (
    <InfoColumn
      icon={goals}
      accordianTips={[
        {
          title: 'What’s an ENS?',
          description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        },
        {
          title: 'Can I use my personal ENS?',
          description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        },
        {
          title: 'Who manages the DAO’s account?',
          description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        },
        {
          title: 'What if I want multiple controllers for my DAO?',
          description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        },
        {
          title: 'I don’t have an ENS name, how can I get one?',
          description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        },
      ]}
    />
  );
}
