/* eslint-disable @typescript-eslint/no-unused-vars */
import { useWalletManager } from '@metaphor-xyz/hooks/dist';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';

import { ENS_REGISTRY_ADDRESS } from './useOrganization';

export function useENSName(ensName: string) {
  const { getProvider } = useWalletManager();
  const [controllerAddress, setControllerAddress] = useState<string | null>(
    '0x9B6568d72A6f6269049Fac3998d1fadf1E6263cc' // brunchclub.eth controller address
  );
  const [avatar, setAvatar] = useState<string | undefined>(
    'https://firebasestorage.googleapis.com/v0/b/meta-door.appspot.com/o/bloody_mary.svg?alt=media&token=55ff3089-5ddf-454e-95dc-c58feb542662' // brunchclub.eth avatar
  );
  const [loading, setLoading] = useState(true);

  return { controllerAddress, avatar };

  // useEffect(() => {
  //   setLoading(true);
  //   (async () => {
  //     if (!ensName) {
  //       setControllerAddress(null);
  //       setAvatar(undefined);
  //       return;
  //     }

  //     const provider = getProvider();

  //     const encodedName = ethers.utils.namehash(ensName);

  //     const ensRegistry = new ethers.Contract(
  //       ENS_REGISTRY_ADDRESS,
  //       ['function recordExists(bytes32) view returns (bool)', 'function resolver(bytes32) view returns (address)'],
  //       provider
  //     );

  //     try {
  //       const exists = await ensRegistry.recordExists(encodedName);
  //       if (!exists) {
  //         setControllerAddress(null);
  //         setAvatar(undefined);
  //         return;
  //       }

  //       // TODO: change to return actual controllerAddress
  //       const resolverAddress = await ensRegistry.resolver(encodedName);

  //       if (resolverAddress !== '0x0000000000000000000000000000000000000000') {
  //         setControllerAddress(resolverAddress);
  //         const resolver = new ethers.Contract(
  //           resolverAddress,
  //           ['function text(bytes32, string) view returns (string)'],
  //           provider
  //         );

  //         const userAvatar = await resolver.text(encodedName, 'avatar');

  //         setAvatar(userAvatar);
  //       }
  //     } catch (error) {
  //       // eslint-disable-next-line no-console
  //       console.error(error);
  //       setControllerAddress(null);
  //       setAvatar(undefined);
  //     } finally {
  //       setLoading(false);
  //     }
  //   })();
  // }, [ensName, getProvider]);

  // return { controllerAddress, avatar, loading };
}
