import { Typography, createStyles, InfoColumn, PageContainer, ThreeColumn, Button, Loading } from '@metaphor-xyz/ui';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Image, Linking, View } from 'react-native';

import cardsSrc from '../../assets/cards.svg';
import goals from '../../assets/icons/light_mode_icons/goals.svg';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import useOnboardingFlow from '../../hooks/useOnboardingFlow';
import useOrganization from '../../hooks/useOrganization';
import useTokenSwap from '../../hooks/useTokenSwap';
import { OnboardingFlowStackParams, StackParams } from '../../navigation';
import { OnboardingFlowSidebar } from './OnboardingFlowSidebar';

export function ExchangeStepInfo() {
  return (
    <InfoColumn
      icon={goals}
      accordianTips={[
        {
          title: 'Understanding Gas Fees',
          description:
            'Gas fee’s are a charge that is determined by the chain. It is a dynamic price depending on the activity on that chain at that moment in time when you write to it. \n \n \n Why we can’t give an actual price? This is because this is only known at the time of writing and so we give a max price, it’s likely the transaction will actually cost less.',
        },
        {
          title: 'Purchase Options',
          description:
            'The purchase options displayed are determined by the currencies we find in your wallet that are detected and can be swapped for this token type.',
        },
        {
          title: 'Where will I receive my token?',
          description: 'Once swapped, you’ll be able to see your new token in your wallet.',
        },
      ]}
    />
  );
}

function ExchangeMainComponent({ orgEnsName }: { orgEnsName: string }) {
  const styles = useStyles();
  const navigation = useNavigation();
  const { onboardingFlow } = useOnboardingFlow(orgEnsName);
  const { organization } = useOrganization(orgEnsName);
  const [tokenAmount, setTokenAmount] = useState(ethers.BigNumber.from(0));

  const role =
    !!onboardingFlow &&
    !!organization &&
    !!organization.roles &&
    !!onboardingFlow.chosenRoleId &&
    organization.roles.find(r => r.id === onboardingFlow.chosenRoleId);

  const token = role && role.requirements && role.requirements[0];

  useEffect(() => {
    if (token) {
      setTokenAmount(ethers.BigNumber.from(token.minBalance));
    }
  }, [token]);

  // todo(carlos): this needs to be moved to a sub-component that falls back to a
  // parameterized link-out
  const { data, loading } = useTokenSwap({
    inTokenId: '0xc778417e063141139fce010982780140aa0cd5ab',
    outTokenId: token ? token.contractId : '0xc778417e063141139fce010982780140aa0cd5ab',
    outTokenAmount: tokenAmount,
  });

  const navigateToNextStep = useCallback(() => {
    // eslint-disable-next-line
      const nextStepId: any = 'success' as keyof StackParams & OnboardingFlowStackParams;
    navigation.navigate(nextStepId);
  }, [navigation]);

  useEffect(() => {
    // if we already have enough tokens, skip this step
    if (data && data.outToken) {
      const balance = ethers.BigNumber.from(data.outToken.balance);

      if (balance.gte(tokenAmount)) {
        navigateToNextStep();
      }
    }
  }, [data, navigateToNextStep, tokenAmount]);

  const openUniswap = useCallback(async () => {
    try {
      const customParams = token
        ? `?outputCurrency=${token.contractId}&exactField=output&exactAmount=${token.minBalance}`
        : '';
      await Linking.openURL(`https://app.uniswap.com/#/swap${customParams}`);
    } finally {
      navigateToNextStep();
    }
  }, [navigateToNextStep, token]);

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <View style={styles.container}>
      <Typography type="h3">Get your community token</Typography>

      <View style={styles.contentContainer}>
        <View style={styles.imageContainer}>
          <Image style={{ height: '100%' }} source={{ uri: cardsSrc }} />
        </View>

        <View style={styles.contentInfo}>
          <Typography type="h3">
            {token ? token.minBalance : '0'} ${data.outToken?.symbol || 'UNKNOWN'}
          </Typography>
          <Typography type="info" color="secondary-black" style={styles.subtitleContainer}>
            To get started each, Contributor member must hold {token ? token.minBalance : 0} $
            {data.outToken?.symbol || 'UNKNOWN'}
          </Typography>
        </View>
      </View>

      <View style={styles.purchaseBox}>
        <Typography type="body-bold">How to get your token?</Typography>
        <Typography color="secondary-black" style={styles.subtitleContainer}>
          We’ve partnered with Uniswap to complete this exchange.{' '}
        </Typography>
        <Button title="Open Uniswap" onPress={openUniswap} />
      </View>
    </View>
  );
}

export default function ExchangeStep({ route }: StackScreenProps<StackParams, 'onboard'>) {
  const breakpoint = useBreakpoint();

  return (
    <PageContainer>
      <ThreeColumn
        leftComponent={breakpoint === 'xs' || breakpoint === 'sm' ? undefined : <OnboardingFlowSidebar />}
        middleComponent={<ExchangeMainComponent orgEnsName={route.params.orgEnsName} />}
        rightComponent={<ExchangeStepInfo />}
      />
    </PageContainer>
  );
}

const useStyles = createStyles(theme => ({
  container: {
    paddingTop: 8,
  },
  contentContainer: {
    marginTop: 24,
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentInfo: {
    flexShrink: 2,
  },
  subtitleContainer: {
    marginTop: 12,
    marginBottom: 12,
  },
  imageContainer: { height: 126, width: 144, marginRight: 22 },

  purchaseBox: {
    maxWidth: 532,
    marginTop: 24,
    border: `1px solid ${theme.colors.outlineGray}`,
    borderRadius: 2,
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 18,
    paddingBottom: 18,
  },
  exchangesContainer: {
    flexDirection: 'row',
    gap: 10,
    marginTop: 12,
  },

  exchangeTypeBox: {
    flexDirection: 'row',
    alignItems: 'center',
    border: `1px solid ${theme.colors.outlineGray}`,
    borderRadius: 2,
    paddingRight: 14,
    paddingLeft: 14,
    paddingTop: 8,
    paddingBottom: 8,
    height: 51,
    width: 154,
  },
  exchangeTypeImageContainer: {
    height: 35,
    width: 35,
    marginRight: 20,
  },
}));
