import { useWalletManager } from '@metaphor-xyz/hooks';
import { Typography, InfoColumn, PageContainer, ThreeColumn, WalletSelector, createStyles } from '@metaphor-xyz/ui';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';

import wallet from '../../assets/icons/light_mode_icons/wallet.svg';
import videoPlaceholder from '../../assets/videoPlaceholder.png';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { OnboardingFlowStackParams, StackParams } from '../../navigation';
import { OnboardingFlowSidebar } from './OnboardingFlowSidebar';

export function ConnectWalletStepInfo() {
  return (
    <InfoColumn
      icon={wallet}
      videoUri={videoPlaceholder}
      linkText="Learn More"
      linkUri="https://twitter.com/gaildewilson/status/1451527658907619329?s=20"
      description="A crypto wallet is an application or hardware device that allows individuals to store and retrieve digital items."
    />
  );
}

function ConnectWalletMainComponent() {
  const styles = useStyles();
  const navigation = useNavigation();
  const { connected } = useWalletManager();

  const navigateToNextStep = useCallback(() => {
    // eslint-disable-next-line
    const nextStepId: any = 'roles' as keyof StackParams & OnboardingFlowStackParams;
    navigation.navigate(nextStepId);
  }, [navigation]);

  useEffect(() => {
    if (connected) {
      navigateToNextStep();
    }
  }, [connected, navigateToNextStep]);

  return (
    <View style={styles.container}>
      <Typography type="h3">Connect your wallet</Typography>
      <Typography color="secondary-black" style={styles.subtitle}>
        Choose a Wallet. You’ll connect through Metaphor, we’re an application that helps DAO’s onboard members. This
        step is just for identification purposes and no funds will be taken.{' '}
      </Typography>
      <WalletSelector />
    </View>
  );
}

export default function ConnectWalletStep() {
  const breakpoint = useBreakpoint();

  return (
    <PageContainer>
      <ThreeColumn
        leftComponent={breakpoint === 'xs' || breakpoint === 'sm' ? undefined : <OnboardingFlowSidebar />}
        middleComponent={<ConnectWalletMainComponent />}
        rightComponent={<ConnectWalletStepInfo />}
      />
    </PageContainer>
  );
}

const useStyles = createStyles(_theme => ({
  container: {
    paddingTop: 8,
  },
  subtitle: {
    marginTop: 16,
    marginBottom: 32,
  },
}));
