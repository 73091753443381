import { AntDesign } from '@expo/vector-icons';
import { Typography, createStyles, PageContainer, ThreeColumn, Button, Loading, NotFound } from '@metaphor-xyz/ui';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { Image } from 'react-native';
import { View } from 'react-native';

import cloudLoadingImageSrc from '../../assets/cloudLoadingImageSrc.png';
import successPartyImagePlaceholderSrc from '../../assets/successPartyImagePlaceholder.png';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { fetchSubmittedApplicationMock, useSubmittedApplicationsMock } from '../../hooks/useMockedData';
import useOrganization, { Organization } from '../../hooks/useOrganization';
import { OnboardingFlowStackParams, StackParams } from '../../navigation';
import { OnboardingFlowSidebar } from './OnboardingFlowSidebar';

function AcceptedStatus({ organization }: { organization: Organization }) {
  const styles = useStyles();
  const { roleId } = useSubmittedApplicationsMock()[0];

  const currentRole = (organization.roles || []).find(r => r.id === roleId);
  const requiresTokens = currentRole?.requirements && currentRole?.requirements.length > 0;

  const navigation = useNavigation();

  const navigateToNextStep = useCallback(() => {
    // eslint-disable-next-line
    const nextStepId: any = 'exchange' as keyof StackParams & OnboardingFlowStackParams;
    navigation.navigate(nextStepId);
  }, [navigation]);

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image style={{ height: '100%' }} source={{ uri: successPartyImagePlaceholderSrc }} />
      </View>
      <Typography type="h3">Congratuations, you’ve been approved!</Typography>
      {requiresTokens ? (
        <Typography type="body" color="secondary-black" style={styles.contentContainer}>
          We’ll now walk you through getting your token
        </Typography>
      ) : (
        <Typography type="body" color="secondary-black" style={styles.contentContainer}>
          We’ll now guide you through getting setup
        </Typography>
      )}

      <Button
        onPress={navigateToNextStep}
        title="Continue"
        postTextComponent={<AntDesign style={styles.buttonIcon} name="arrowright" size={20} />}
      />
    </View>
  );
}

function PendingStatus({ organization }: { organization: Organization }) {
  const styles = useStyles();
  const breakpoint = useBreakpoint();

  return (
    <View style={styles.pendingStepContainer}>
      {breakpoint !== 'xs' && breakpoint !== 'sm' && (
        <View style={styles.loadingImageContainer}>
          <Image style={{ height: '100%' }} source={{ uri: cloudLoadingImageSrc }} />
        </View>
      )}
      <Typography type="h3">Your details have been sent</Typography>

      <View style={styles.contentContainer}>
        <Typography type="body" color="secondary-black">
          Typically, {organization.name} responds
        </Typography>
        <Typography type="body">within a 7 days.</Typography>
      </View>

      {(breakpoint === 'xs' || breakpoint === 'sm') && (
        <View style={styles.loadingImageContainerSm}>
          <Image style={{ height: '100%' }} source={{ uri: cloudLoadingImageSrc }} />
        </View>
      )}
      <View style={{ marginTop: 75 }}>
        <Typography type="h3">While you wait...</Typography>

        <Typography type="body" color="secondary-black" style={styles.contentContainer}>
          Check out these other communities using Metaphor to onboard new members.
        </Typography>
      </View>
    </View>
  );
}

function StatusMainComponent({ organization }: { organization: Organization }) {
  const [mockedStatus] = useState('pending');

  const { status } = fetchSubmittedApplicationMock(mockedStatus);

  return (
    <>
      {status === 'accepted' ? (
        <AcceptedStatus organization={organization} />
      ) : (
        <PendingStatus organization={organization} />
      )}
    </>
  );
}

export default function StatusStep({ route }: StackScreenProps<StackParams, 'onboard'>) {
  const breakpoint = useBreakpoint();
  const { loading, organization } = useOrganization(route.params.orgEnsName);

  if (loading) {
    return <Loading />;
  }

  if (!organization) {
    return <NotFound />;
  }

  return (
    <PageContainer>
      <ThreeColumn
        leftComponent={breakpoint === 'xs' || breakpoint === 'sm' ? undefined : <OnboardingFlowSidebar />}
        middleComponent={<StatusMainComponent organization={organization} />}
      />
    </PageContainer>
  );
}

const useStyles = createStyles(_theme => ({
  container: {
    alignItems: 'center',
    paddingTop: 8,
  },
  contentContainer: {
    marginTop: 16,
    marginBottom: 22,
  },
  imageContainer: {
    width: 306,
    height: 166,
    marginBottom: 32,
  },
  pendingStepContainer: {
    position: 'relative',
    paddingTop: 8,
  },
  loadingImageContainer: {
    width: 276,
    height: 269,
    alignSelf: 'flex-end',
    position: 'absolute',
  },
  loadingImageContainerSm: {
    width: 276,
    height: 269,
    paddingTop: 24,
  },
  buttonIcon: {
    marginLeft: 8,
    color: 'white',
  },
}));
