import { SimpleVerticalStepper, SimpleVerticalStepperItem } from '@metaphor-xyz/ui';
import { useNavigationState, useRoute } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

export const DAO_ONBOARDING_STEPS_LABELS: { [key: string]: string } = {
  connect: 'Connect',
  roles: 'Choose position',
  approval: 'Approval',
  exchange: 'Exchange',
  success: 'Success',
};

export function OnboardingFlowSidebar() {
  const routeNames = useNavigationState(state => state.routeNames);
  const currentRoute = useRoute().name;

  return (
    <View style={{ marginTop: 10, paddingTop: 10 }}>
      <SimpleVerticalStepper activeItem={currentRoute}>
        {routeNames.map(route => (
          <SimpleVerticalStepperItem key={route} id={route} label={DAO_ONBOARDING_STEPS_LABELS[route]} />
        ))}
      </SimpleVerticalStepper>
    </View>
  );
}
