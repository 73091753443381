import { useWalletManager } from '@metaphor-xyz/hooks/dist';
import { PageContainer, createStyles, OutlineWithSections, NameWithIcon, Loading, NotFound } from '@metaphor-xyz/ui';
import type { OutlineWithSectionsItem } from '@metaphor-xyz/ui/dist/OutlineWithSections';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useState } from 'react';
import { View, Image } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import pancakesSrc from '../assets/brunchclub/pancakes.svg';
import crownSrc from '../assets/crown.svg';
import ApprovalsSection from '../components/ManagerExperience/ApprovalsSection/ApprovalsSection';
import GuidesSection from '../components/ManagerExperience/GuidesSection';
import HomeSection from '../components/ManagerExperience/HomeSection';
import MembersSection from '../components/ManagerExperience/MembersSection';
import RolesSection from '../components/ManagerExperience/RolesSection';
import SettingsSection from '../components/ManagerExperience/SettingsSection';
import { isDevManager } from '../config';
import useOrganization from '../hooks/useOrganization';
import { StackParams } from '../navigation';

export default function ManagerScreen({ route }: StackScreenProps<StackParams, 'community'>) {
  const styles = useStyles();
  const [isHomeSection, setIsHomeSection] = useState(true);
  const { mainAccount, connecting } = useWalletManager();
  const { loading, organization } = useOrganization(route.params.orgEnsName);

  const onPressItem = useCallback((id: string) => {
    if (id === 'home') {
      setIsHomeSection(true);
    } else {
      setIsHomeSection(false);
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!organization) {
    return <NotFound />;
  }

  const outlineItems = [
    {
      outlineProps: { id: 'home', label: 'Home' },
      content: <HomeSection organization={organization} />,
    },
    {
      outlineProps: { id: 'guides', label: 'Guides' },
      content: <GuidesSection />,
    },
    isDevManager(mainAccount) && {
      outlineProps: { id: 'approvals', label: 'Approvals' },
      content: <ApprovalsSection organization={organization} />,
    },
    {
      outlineProps: { id: 'members', label: 'Members' },
      content: <MembersSection organization={organization} />,
    },
    {
      outlineProps: { id: 'roles', label: 'Roles' },
      content: <RolesSection organization={organization} />,
    },
    {
      outlineProps: { id: 'settings', label: 'Settings' },
      content: <SettingsSection organization={organization} />,
    },
  ].filter(v => !!v) as OutlineWithSectionsItem[];

  if (connecting) {
    return (
      <PageContainer>
        <ActivityIndicator size="large" />
      </PageContainer>
    );
  }

  return (
    <View style={styles.container}>
      <OutlineWithSections
        outlineStepperHeaderComponent={
          <View style={{ width: '100%' }}>
            <NameWithIcon
              name={organization.name}
              icon={organization.images?.logo || ''}
              nameTyporgraphyType="small-button"
              iconContainerStyles={styles.daoLogoOutlineStyles}
            />
          </View>
        }
        outlineStepperLowerHeaderComponent={
          <View style={styles.roleIconsRow}>
            {/* TODO: Look at the role this user has */}
            <View style={styles.controllerIconStyle}>
              <View style={{ width: 14, height: 12 }}>
                <Image style={{ height: '100%' }} source={{ uri: crownSrc }} />
              </View>
            </View>

            <View style={styles.membershipIconStyle}>
              <Image style={{ height: '100%' }} source={{ uri: pancakesSrc }} />
            </View>
          </View>
        }
        items={outlineItems}
        onPressItem={onPressItem}
        contentStyle={styles.outlineContentStyle}
        containerStyle={styles.outlineContainerStyle}
        outlineContainerStyle={[
          styles.outlineOutlineContainerStyles,
          {
            backgroundColor: isHomeSection ? undefined : '#f7f6f4',
          },
        ]}
      />
    </View>
  );
}

const useStyles = createStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
  },

  outlineContainerStyle: {
    width: '100%',
    height: '100%',
  },
  outlineContentStyle: { flexGrow: 2 },
  outlineOutlineContainerStyles: {},

  daoLogoOutlineStyles: {
    border: `1px solid ${theme.colors.outlineGray}`,
    borderRadius: 2,
  },

  roleIconsRow: {
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
  },
  membershipIconStyle: {
    border: `1px solid ${theme.colors.outlineGray}`,
    borderRadius: 2,
    height: 30,
    width: 30,
  },
  controllerIconStyle: {
    backgroundColor: '#DFF4DE',
    borderRadius: 100,
    overflow: 'hidden',
    height: 35,
    width: 35,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
