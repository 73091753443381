import { Typography, createStyles, OutlineWithSections, Loading, NameWithDavatar } from '@metaphor-xyz/ui';
import React from 'react';
import { View } from 'react-native';

import type { ApprovalStatus } from '../../__generated__/gqlTypes';
import { Organization } from '../../hooks/useOrganization';
import useOrganizationApplications, { Application } from '../../hooks/useOrganizationApplications';

function MemberList({ applications = [], role = '' }: { applications: Application[]; role: string }) {
  const styles = useStyles();

  return (
    <View style={styles.memberList}>
      <Typography type="h3">{role}</Typography>
      <View style={styles.listContainer}>
        {applications?.map(({ submitter }) => {
          return (
            <View key={submitter.id} style={styles.listItem}>
              <NameWithDavatar name={submitter.id} address={submitter.id} />
            </View>
          );
        })}
      </View>
    </View>
  );
}

export default function MembersSection({ organization }: { organization: Organization }) {
  const styles = useStyles();
  const { applications = [], loading } = useOrganizationApplications(organization.ensName, {
    status: 'APPROVED' as ApprovalStatus.InProgress,
  });

  if (loading) {
    return <Loading />;
  }

  const items = organization?.roles?.map(role => {
    const apps = applications?.filter(app => {
      return app.committee.committeeId == role.committeeId;
    });
    return {
      outlineProps: {
        id: role.id,
        label: role.name,
        image: `${role.icon}`,
        // TODO: Requirements placeholder
        sublabel: role.requirements?.length ? 'Has Requirements' : 'No Requirements',
      },
      content: <MemberList role={role.name || ''} applications={apps || []} />,
    };
  });

  return (
    <View style={styles.container}>
      <OutlineWithSections
        outlineStepperHeaderComponent={
          <Typography color="secondary-black" type="info" style={{ width: '100%' }}>
            Members
          </Typography>
        }
        items={items || []}
        containerStyle={styles.outlineContainerStyle}
        contentStyle={styles.outlineContentStyle}
      />
    </View>
  );
}

const useStyles = createStyles(theme => ({
  container: {
    width: '100%',
    alignItems: 'center',
    height: '100%',
  },
  outlineContainerStyle: { width: '100%', height: '100%' },
  outlineContentStyle: { flexGrow: 2, padding: 32 },
  memberList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  listItem: {
    display: 'flex',
    padding: 16,
    alignContent: 'center',

    borderBottomWidth: 1,
    borderColor: theme.colors.outlineGray,
    borderStyle: 'solid',
  },
  listContainer: {
    borderTopWidth: 1,
    borderColor: theme.colors.outlineGray,
    borderStyle: 'solid',
  },
}));
