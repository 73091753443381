import { Typography, createStyles } from '@metaphor-xyz/ui';
import React from 'react';
import { View } from 'react-native';

import { useBreakpoint } from '../../hooks/useBreakpoint';

export interface TitleAndMissionViewProps {
  title: string;
  mission: string;
}

export default function TitleAndMissionView({ title, mission }: TitleAndMissionViewProps) {
  const styles = useStyles();
  const breakpoint = useBreakpoint();

  return (
    <View>
      <Typography type="body-bold" color="secondary-black" style={styles.welcomeContainer}>
        Welcome to
      </Typography>
      <View style={[styles.infoContainer, (breakpoint === 'xs' || breakpoint === 'sm') && styles.infoContainerSm]}>
        <View style={styles.titleContainer}>
          <Typography type="h1">{title}</Typography>
        </View>
        <Typography type="body-bold" color="secondary-black" style={styles.missionContainer}>
          {mission}
        </Typography>
      </View>
    </View>
  );
}

const useStyles = createStyles(_theme => ({
  welcomeContainer: {
    maxWidth: 100,
  },
  infoContainer: {
    flexDirection: 'row',
    maxWidth: '100%',
    gap: 52,
  },
  infoContainerSm: {
    flexWrap: 'wrap',
    gap: 0,
    display: 'flex',
  },
  titleContainer: {
    maxWidth: 455,
    flexGrow: 2,
    marginTop: 16,
  },
  missionContainer: {
    marginTop: 16,
    maxWidth: 522,
  },
}));
