import { createStyles, PaperContainer, Typography, Link } from '@metaphor-xyz/ui';
import React from 'react';
import { View } from 'react-native';

export function MembershipPaper() {
  const styles = useStyles();

  return (
    <PaperContainer>
      <Typography type="h3">Membership</Typography>
      <Typography color="secondary-black" style={styles.upperMargin}>
        Learn more about membership above!
      </Typography>
    </PaperContainer>
  );
}

export function Discor101Paper() {
  const styles = useStyles();

  return (
    <PaperContainer>
      <Typography type="h3">Discord 101</Typography>
      <Typography color="secondary-black" style={styles.upperMargin}>
        We know Discord looks a little scary, but don't worry! We're here to help!
      </Typography>
      <Typography type="body-bold" color="secondary-black" style={styles.upperMargin}>
        Discord resources:
      </Typography>

      <View style={styles.upperMargin}>
        <Link text="Getting started with Discord" url="https://support.discord.com/hc/en-us/categories/115000217151" />
      </View>

      <View style={styles.upperMargin}>
        <Link text="FAQs" url="https://support.discord.com/hc/en-us/categories/115000168371" />
      </View>

      <View style={styles.upperMargin}>
        <Link text="Support" url="https://support.discord.com/hc/en-us" />
      </View>
    </PaperContainer>
  );
}

export function HowToIntroPaper() {
  const styles = useStyles();

  return (
    <PaperContainer>
      <Typography type="h3">How to intro</Typography>
      <Typography color="secondary-black" style={styles.upperMargin}>
        The community is so excited to meet you! This is the opportunity to kick things off right! Let's get your first
        impressions set!
      </Typography>
      <Typography type="body-bold" color="secondary-black" style={styles.upperMargin}>
        Tips:
      </Typography>
      <Typography color="secondary-black" style={styles.upperMargin}>
        ✨ Add emojis
      </Typography>
      <Typography color="secondary-black">💪 Talk about your skills</Typography>
      <Typography color="secondary-black">🌱 Mention your interests and what you want to learn</Typography>
    </PaperContainer>
  );
}

export function ProjectsPaper() {
  const styles = useStyles();

  return (
    <PaperContainer>
      <Typography type="h3">Projects & Comp</Typography>
      <Typography color="secondary-black" style={styles.upperMargin}>
        We run many projects every season. Check out some of the best projects out there!
      </Typography>
      <Typography type="body-bold" color="secondary-black" style={styles.upperMargin}>
        Season 12 Overview
      </Typography>
      <Typography color="secondary-black" style={styles.upperMargin}>
        This season will focus on building things.
      </Typography>
      <Typography type="body-bold" color="secondary-black" style={styles.upperMargin}>
        Projects
      </Typography>
      <Typography color="secondary-black" style={styles.upperMargin}>
        🛠 Build build build.
      </Typography>
      <Typography color="secondary-black">🛠 Build build build.</Typography>
      <Typography color="secondary-black">🛠 Build build build.</Typography>
    </PaperContainer>
  );
}

export function CommunityPaper() {
  const styles = useStyles();

  return (
    <PaperContainer>
      <Typography type="h3">Community</Typography>
      <Typography color="secondary-black" style={styles.upperMargin}>
        Brunch Club is committed to building in public, so you can learn alongside us. There's a lot going on, so here
        are some ideas on how to track what we're up to:
      </Typography>
      <Typography type="body-bold" color="secondary-black" style={styles.upperMargin}>
        A brief history
      </Typography>
      <Typography color="secondary-black" style={styles.upperMargin}>
        It all started with a Mirror crowdfund, during which 48.51 ETH were raised from 122 backers, in exchange for
        $BRUNCH tokens. The initial plan was to build an MVP that would primarily focus on both DAO tools (write-ups &
        video demos) & DAO resources (curated articles, Twitter threads, etc).
      </Typography>
      <Typography type="body-bold" color="secondary-black" style={styles.upperMargin}>
        Building in public allows others to learn from our accomplishments (and mistakes 😅). It helps us:
      </Typography>
      <Typography color="secondary-black" style={styles.upperMargin}>
        🌈 Build a community.
      </Typography>
      <Typography color="secondary-black">🌈 Get regular feedback.</Typography>
      <Typography color="secondary-black">🌈 Hold ourselves accountable.</Typography>

      <Typography color="secondary-black" style={styles.upperMargin}>
        Most of the coordination activity takes place over discord in dedicated chat and video channels. For important
        matters like governance proposals and compensation decisions, we use specialized web3 tools to vote.
      </Typography>

      <Typography color="secondary-black" style={styles.upperMargin}>
        • We use Snapshot for governance proposals.
      </Typography>
      <Typography color="secondary-black">• We use Coordinape to vote on compensation for contributors.</Typography>
    </PaperContainer>
  );
}

const useStyles = createStyles(_theme => ({
  upperMargin: {
    marginTop: 16,
  },
}));
