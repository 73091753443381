import { Dropdown, IconButton } from '@metaphor-xyz/ui';
import React, { useCallback, useState } from 'react';
import { Image, View } from 'react-native';

import brunchClubLogoSrc from '../assets/brunchclub/bloody_mary.svg';
import discordIconSrc from '../assets/discordLogo.svg';
import membershipFace2 from '../assets/membershipFace2.png';
import metaphorLogoSrc from '../assets/metaphorLogo.svg';
import speakerphoneFilledSrc from '../assets/speakerphone-filled.svg';
import speakerphoneSrc from '../assets/speakerphone.svg';

export default function NotificationButtonAndDropdown() {
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(true);

  const notifications = [
    { title: 'Brunch Club', subtitle: 'Application accepted', icon: brunchClubLogoSrc },
    { title: 'Discord', subtitle: 'Discord announcement', icon: discordIconSrc },
    { title: 'Metaphor', subtitle: 'Update your profile', icon: metaphorLogoSrc },
    { title: 'Lion DAO', subtitle: 'New project', icon: membershipFace2 },
  ];

  const onCloseNotifications = useCallback(() => {
    setNotificationsOpen(false);
  }, []);

  const onOpenNotifications = useCallback(() => {
    setHasNewNotifications(false);
    setNotificationsOpen(true);
  }, []);

  return (
    <Dropdown
      open={notificationsOpen}
      onClose={onCloseNotifications}
      anchorComponent={
        <View style={{ marginRight: 14 }}>
          <IconButton
            color="unfilled"
            onPress={onOpenNotifications}
            focused={notificationsOpen}
            showBadge={hasNewNotifications}
            iconComponent={
              <View style={{ height: 16, width: 16 }}>
                <Image
                  style={{ height: '100%' }}
                  source={{ uri: hasNewNotifications ? speakerphoneFilledSrc : speakerphoneSrc }}
                />
              </View>
            }
          />
        </View>
      }
      items={notifications}
    />
  );
}
