import { ExpandableActionSelector, ExpandableActionSelectorItem } from '@metaphor-xyz/ui';
import React, { useCallback } from 'react';
import { Linking } from 'react-native';

import discordIcon from '../../assets/discordLogo.svg';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { OrganizationRole } from '../../hooks/useOrganization';

const metaphorDiscordLink = 'https://discord.com/invite/aqvAHREdxT';

interface RolesSectionProps {
  roles: OrganizationRole[];
  onPressRole: (_membershipId: string) => () => void;
}

export default function RolesSection({ roles, onPressRole }: RolesSectionProps) {
  const breakpoint = useBreakpoint();
  const onPressDiscord = useCallback(async () => {
    await Linking.openURL(metaphorDiscordLink);
  }, []);

  if (roles.length === 0) {
    return null;
  }

  const joinDiscordAction = { id: 'join_discord', customButtonType: discordIcon, onPress: onPressDiscord };

  return (
    <ExpandableActionSelector noBorderRadius={breakpoint === 'xs' || breakpoint === 'sm'}>
      {roles.map(role => {
        const tokenString = (role.requirements || []).map(req => `${req.minBalance} ${req.name}`).join(' + ');
        return (
          <ExpandableActionSelectorItem
            key={role.id}
            name={role.name}
            icon={role.icon || undefined}
            tokenRequirementString={tokenString}
            hasApplication={!!role.formId}
            actions={
              !role.requirements || role.requirements.length === 0
                ? [joinDiscordAction]
                : [{ id: role.id, title: 'Apply', onPress: onPressRole(role.id) }]
            }
            description={role.descriptionLong || undefined}
          />
        );
      })}
    </ExpandableActionSelector>
  );
}
