import { useQuery, gql } from '@apollo/client';
import { useCeramic, useWalletManager } from '@metaphor-xyz/hooks';
import { ethers } from 'ethers';
import { useCallback } from 'react';

import { GetRequestsQuery, GetRequestsQueryVariables } from '../__generated__/gqlTypes';

const COMMITTEE_MANAGER_CONTRACT = '0x24afbdab603ffa376b722f18b67a957affa3ee13';

const QUERY = gql`
  query GetRequests($submitter: String!) {
    requests(where: { submitter: $submitter }) {
      id
      formSubmissionURI
      status

      committee {
        id
        metadataURI
      }

      submitter {
        id
      }
    }
  }
`;

/**
 * An answer in a form submission for an application.
 *
 */
export interface Answer {
  fieldId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

/**
 * Loads applications submitted by the wallet given by `address`.
 *
 */
export default function useApplications(address?: string) {
  const { createStream } = useCeramic();
  const { mainAccount, getSigner } = useWalletManager();
  const { data, loading } = useQuery<GetRequestsQuery, GetRequestsQueryVariables>(QUERY, {
    variables: { submitter: address || mainAccount || '' },
    context: { subgraph: 'metaphor-xyz/approval-protocol' },
    skip: !!mainAccount,
  });

  const submitApplication = useCallback(
    async (committeeId: string, reference: string, formId: string, answers: Answer[]) => {
      const formSubmission = await createStream({
        formReference: reference,
        formId,
        answers,
      });

      const formSubmissionURI = formSubmission.id.toUrl();

      const signer = await getSigner();

      if (!signer) {
        throw new Error('not connected to wallet');
      }

      const manager = new ethers.Contract(
        COMMITTEE_MANAGER_CONTRACT,
        ['function createApprovalRequest(uint256,string memory) returns (uint256)'],
        signer
      );

      const tx = await manager.createApprovalRequest(committeeId, formSubmissionURI);

      return tx;
    },
    [createStream, getSigner]
  );

  return {
    submitApplication,
    loading,
    applications: data?.requests,
  };
}
