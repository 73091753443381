import { AntDesign } from '@expo/vector-icons';
import {
  ThreeColumn,
  Typography,
  PageContainer,
  createStyles,
  Button,
  Loading,
  OrgProfileCard,
} from '@metaphor-xyz/ui';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { Image, View } from 'react-native';

import bloodyMarySrc from '../assets/brunchclub/bloody_mary.svg';
import coffeeSrc from '../assets/brunchclub/coffee.svg';
import pancakesSrc from '../assets/brunchclub/pancakes.svg';
import communitiesEmptyStateSrc from '../assets/communities_empty_state.png';
import useApplications from '../hooks/useApplications';
import useOrganization from '../hooks/useOrganization';
import { StackParams } from '../navigation';

export default function HomeScreen({ navigation }: StackScreenProps<StackParams, 'home'>) {
  const styles = useStyles();

  // TODO: this is a hack, fix this to get the roles, applications, & collect into actual org cards
  const { loading, applications } = useApplications();
  const { loading: orgLoading, organization: organization } = useOrganization('brunchclub.eth');
  const applicationsWithRoleInfoForOrg =
    applications
      ?.map(app => {
        const foundRole = organization?.roles?.find(r => r.committeeId === app.committee.id);

        return { ...app, role: foundRole };
      })
      .filter(app => !!app.role) || [];
  const approvedRolesForOrg = applicationsWithRoleInfoForOrg.filter(app => app.status === 'APPROVED');
  const pendingRolesForOrg = applicationsWithRoleInfoForOrg.filter(app => app.status === 'IN_PROGRESS');
  // TODO: Do something with rejected status??
  // const rejectedRolesForOrg = applicationsWithRoleInfoForOrg.filter(app => app.status === 'REJECTED');

  const onNavigate = useCallback(() => {
    navigation.navigate('explore');
  }, [navigation]);

  const onNavigateToOnboardingFlow = useCallback(
    (orgEnsName: string) => () => {
      navigation.navigate('onboard', {
        orgEnsName,
      });
    },
    [navigation]
  );

  const emptyStateComponent = (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image style={{ height: '100%' }} source={{ uri: communitiesEmptyStateSrc }} />
      </View>
      <Typography type="h3">You’re not a member of any DAOs yet</Typography>
      <Typography color="secondary-black">Add your first to build your portfolio</Typography>
      <Button
        title="Explore all"
        onPress={onNavigate}
        color="primary"
        postTextComponent={<AntDesign style={styles.buttonIcon} name="arrowright" size={20} />}
      />
    </View>
  );

  if (loading || orgLoading) {
    return (
      <PageContainer>
        <Loading size="large" />
      </PageContainer>
    );
  }

  if (!organization || !applications || applications.length === 0) {
    return (
      <PageContainer>
        <ThreeColumn middleComponent={emptyStateComponent} />
      </PageContainer>
    );
  }

  // Todo: get date when this was approved
  const memberStatusNote =
    approvedRolesForOrg.length > 0 ? (
      <Typography color="secondary-black">{'Member since ‘22'}</Typography>
    ) : (
      <Typography color="secondary-black">Following</Typography>
    );

  const pendingStatusNote =
    pendingRolesForOrg.length > 0 ? (
      <Typography color="outline-gray">
        {`(${applicationsWithRoleInfoForOrg.length > 1 ? 'Role' : 'Member'} request pending)`}
      </Typography>
    ) : undefined;

  return (
    <PageContainer>
      <ThreeColumn
        middleComponent={
          <View>
            <Typography type="h2">Your DAOs</Typography>

            <OrgProfileCard
              key={organization.ensName}
              title={organization.name}
              imageUri={organization?.images?.logo || bloodyMarySrc}
              // TODO: Correctly handle redirecting to the org's /community page or /onboard flow. For now just go to /onboard
              onPress={onNavigateToOnboardingFlow(organization.ensName)}
              descriptionComponent={
                <View style={styles.contentContainer}>
                  {memberStatusNote}
                  {pendingStatusNote}

                  <View style={styles.rolesImagesContainer}>
                    {pendingRolesForOrg.map(pendingRole => (
                      <View style={[styles.roleImageContainer, styles.pendingOpacity]}>
                        <Image style={{ height: '100%' }} source={{ uri: pendingRole.role?.icon || coffeeSrc }} />
                      </View>
                    ))}
                    {approvedRolesForOrg.map(approvedRole => (
                      <View style={styles.roleImageContainer}>
                        <Image style={{ height: '100%' }} source={{ uri: approvedRole.role?.icon || pancakesSrc }} />
                      </View>
                    ))}
                  </View>
                </View>
              }
            />
          </View>
        }
      />
    </PageContainer>
  );
}

const useStyles = createStyles(_theme => ({
  container: {
    width: '100%',
    alignItems: 'center',
    gap: 24,
  },
  imageContainer: {
    marginTop: 100,
    marginBottom: 54,
    height: 204,
    width: 296,
    maxWidth: '100%',
  },
  buttonIcon: {
    marginLeft: 8,
  },

  contentContainer: {
    alignItems: 'flex-start',
  },
  rolesImagesContainer: {
    marginTop: 32,
    flexDirection: 'row',
    gap: 10,
  },
  roleImageContainer: {
    height: 38,
    width: 38,
    borderRadius: 2,
  },
  pendingOpacity: {
    opacity: 0.5,
  },
}));
