import { createStyles, Typography } from '@metaphor-xyz/ui';
import React from 'react';
import { View, Image } from 'react-native';

import { OrganizationRole } from '../../hooks/useOrganization';

export function RoleDetails({ role }: { role: OrganizationRole }) {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Typography type="h3">{role.name}</Typography>
      <View>
        <Image style={{ width: 86, height: 86 }} source={{ uri: role.icon || '' }} />
      </View>
      <View>
        <Typography style={styles.label} type="body-bold">
          One Liner
        </Typography>
        <Typography type="body">{role.description || ''}</Typography>
      </View>
      <View>
        <Typography style={styles.label} type="body-bold">
          Full Description
        </Typography>
        <Typography type="body">{role.descriptionLong || ''}</Typography>
      </View>

      <View>
        <Typography style={styles.label} type="body-bold">
          Application
        </Typography>
        {/** TODO: Populate with form name / link to form ? */}
        <Typography type="body">{role.formId ? 'Has Application' : 'No Application'}</Typography>
      </View>

      <View>
        <Typography style={styles.label} type="body-bold">
          Token Access Requirements
        </Typography>
        {/** TODO: replace with human readable requirements */}
        <Typography type="body">{role.requirements?.length ? 'Has Requirements' : 'No Requirements'}</Typography>
      </View>
    </View>
  );
}

const useStyles = createStyles(_theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  label: {
    marginBottom: 8,
  },
}));
