import { Typography, createStyles, OutlineWithSections } from '@metaphor-xyz/ui';
import React from 'react';
import { View } from 'react-native';

import { Organization } from '../../hooks/useOrganization';
import { RoleDetails } from './RoleDetails';

export default function RolesSection({ organization }: { organization: Organization }) {
  const styles = useStyles();

  const items = organization?.roles?.map(role => {
    return {
      outlineProps: {
        id: role.id,
        image: `${role.icon}`,
        label: role.name,
        // TODO: replace with human readable requirements
        sublabel: role.requirements?.length ? 'Has Requirements' : 'No Requirements',
      },
      content: <RoleDetails role={role} />,
    };
  });

  return (
    <View style={styles.container}>
      <OutlineWithSections
        outlineStepperHeaderComponent={
          <Typography color="secondary-black" type="info" style={{ width: '100%' }}>
            Roles
          </Typography>
        }
        items={items || []}
        containerStyle={styles.outlineContainerStyle}
        contentStyle={styles.outlineContentStyle}
      />
    </View>
  );
}

const useStyles = createStyles(_theme => ({
  container: {
    width: '100%',
    alignItems: 'center',
    height: '100%',
  },
  outlineContainerStyle: { width: '100%', height: '100%' },
  outlineContentStyle: { flexGrow: 2, padding: 32 },
}));
