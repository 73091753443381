import { useWalletManager, WalletProvider } from '@metaphor-xyz/hooks';
import {
  ActionSelector,
  ActionSelectorItem,
  createStyles,
  Dropdown,
  ProfileComponent,
  Typography,
  Modal,
} from '@metaphor-xyz/ui';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import metamaskSrc from '../assets/metamask.svg';
import walletconnectSrc from '../assets/walletconnect.svg';

export default function ProfileComponentAndDropdown() {
  const styles = useStyles();
  const { mainAccount, disconnectProvider, connectProvider, connecting, ensName } = useWalletManager();
  const [displayOpen, setDisplayOpen] = useState(false);

  const onDisconnect = useCallback(async () => {
    try {
      disconnectProvider();
    } finally {
      setDisplayOpen(false);
    }
  }, [disconnectProvider]);

  const onPressProfileComponent = useCallback(() => {
    setDisplayOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setDisplayOpen(false);
  }, []);

  const onPressWalletProvider = useCallback(
    async (id: string) => {
      if (connecting) {
        return;
      }
      try {
        connectProvider(id as WalletProvider);
      } finally {
        setDisplayOpen(false);
      }
    },
    [connectProvider, connecting]
  );

  const profileComponent = (
    <ProfileComponent
      address={mainAccount || undefined}
      name={ensName}
      onPress={onPressProfileComponent}
      loading={connecting}
      focused={displayOpen}
    />
  );

  if (!mainAccount) {
    return (
      <>
        <Modal open={displayOpen} onClose={onClose}>
          <View>
            <Typography type="h3">Connect your wallet</Typography>
            <Typography color="secondary-black" style={styles.subtitle}>
              Choose a Wallet. You’ll connect through Metaphor, we’re an application that helps DAO’s onboard members.
              This step is just for identification purposes and no funds will be taken.{' '}
            </Typography>

            {/*
               For some reason <WalletSelector /> does not work here.
                I think it has to do with the modal being in a different "portal" or something that
                takes out the ability to get hooks. Putting this in manually for now
            */}
            <ActionSelector onPress={onPressWalletProvider}>
              <ActionSelectorItem id="metamask" icon={metamaskSrc} name="MetaMask" />
              <ActionSelectorItem id="walletconnect" icon={walletconnectSrc} name="WalletConnect" />
            </ActionSelector>
          </View>
        </Modal>
        {profileComponent}
      </>
    );
  }

  return (
    <Dropdown
      open={displayOpen}
      onClose={onClose}
      anchorComponent={profileComponent}
      items={[{ title: 'Disconnect wallet', onPress: onDisconnect }]}
    />
  );
}

const useStyles = createStyles(_theme => ({
  subtitle: {
    marginTop: 16,
    marginBottom: 32,
  },
}));
