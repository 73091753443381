import { createStyles, Input, Typography } from '@metaphor-xyz/ui';
import React from 'react';
import { Text, View } from 'react-native';
import { RadioButton } from 'react-native-paper';

const daoPurposeRadios = [
  {
    label: 'Social',
    value: 'social',
  },
  {
    label: 'Work',
    value: 'work',
  },
  {
    label: 'Invest',
    value: 'invest',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export default function DaoStructure() {
  const styles = useStyles();

  const [daoPurposeValue, setDaoPurposeValue] = React.useState('');
  const [memberRolesValue, setMemberRolesValue] = React.useState('');
  const [numberOfMemberRoles, setNumberOfMemberRoles] = React.useState('');
  const [applicationAprovalValue, setApplicationAprovalValue] = React.useState('');

  return (
    <View>
      <Typography type="h3" style={styles.lowerMargin}>
        What’s the purpose of this DAO?
      </Typography>

      <View style={styles.box}>
        <RadioButton.Group onValueChange={newValue => setDaoPurposeValue(newValue)} value={daoPurposeValue}>
          {daoPurposeRadios.map(item => (
            <View key={item.value} style={styles.radio}>
              <RadioButton value={item.value} />
              <Text>{item.label}</Text>
            </View>
          ))}
        </RadioButton.Group>
      </View>

      <Typography type="body" style={styles.lowerMargin}>
        Do you have multiple member roles?
      </Typography>

      <View style={styles.box}>
        <RadioButton.Group onValueChange={newValue => setMemberRolesValue(newValue)} value={memberRolesValue}>
          <View style={styles.radio}>
            <RadioButton value="yes" />
            <Text>Yes</Text>
          </View>

          {memberRolesValue === 'yes' && (
            <View style={styles.howMany}>
              <Input
                label="How many? (this can be changed later)"
                value={numberOfMemberRoles}
                onChangeText={setNumberOfMemberRoles}
                keyboardType="numeric"
              />
            </View>
          )}

          <View style={styles.radio}>
            <RadioButton value="no" />
            <Text>No, we don’t gate any membership access</Text>
          </View>
        </RadioButton.Group>
      </View>

      <Typography type="h3" style={styles.lowerMargin}>
        Do any roles require an application approval?
      </Typography>

      <View style={styles.box}>
        <RadioButton.Group
          onValueChange={newValue => setApplicationAprovalValue(newValue)}
          value={applicationAprovalValue}
        >
          <View style={styles.radio}>
            <RadioButton value="yes" />
            <Text>Yes</Text>
          </View>

          <View style={styles.radio}>
            <RadioButton value="no" />
            <Text>No</Text>
          </View>
        </RadioButton.Group>
      </View>
    </View>
  );
}

const useStyles = createStyles(theme => ({
  radio: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  howMany: {
    marginLeft: 36,
    marginBottom: 24,
  },
  box: {
    borderColor: theme.colors.outlineGray,
    borderWidth: 1,
    padding: 12,
    marginBottom: 32,
  },
  lowerMargin: {
    marginBottom: 16,
  },
}));
