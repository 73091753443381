import { OutlineWithSections } from '@metaphor-xyz/ui/dist';
import React from 'react';

import bookIcon from '../../assets/book.svg';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import {
  CommunityPaper,
  Discor101Paper,
  HowToIntroPaper,
  MembershipPaper,
  ProjectsPaper,
} from '../CommunityOutlineSections';

export default function PagesSection() {
  const breakpoint = useBreakpoint();
  return (
    <OutlineWithSections
      items={[
        {
          outlineProps: { id: 'community', icon: bookIcon, label: 'Community' },
          content: <CommunityPaper />,
        },
        {
          outlineProps: { id: 'projects-and-comp', icon: bookIcon, label: 'Projects & Comp' },
          content: <ProjectsPaper />,
        },
        {
          outlineProps: { id: 'how-to-intro', icon: bookIcon, label: 'How to intro' },
          content: <HowToIntroPaper />,
        },
        {
          outlineProps: { id: 'discord-101', icon: bookIcon, label: 'Discord 101' },
          content: <Discor101Paper />,
        },
        {
          outlineProps: { id: 'Membership', icon: bookIcon, label: 'Membership' },
          content: <MembershipPaper />,
        },
      ]}
      contentStyle={{
        height: breakpoint === 'xs' || breakpoint === 'sm' ? undefined : 650,
        width: breakpoint === 'md' ? '77%' : undefined,
      }}
    />
  );
}
