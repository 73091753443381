import { StatSection } from '@metaphor-xyz/ui/dist';
import React from 'react';

interface Stat {
  title: string;
  bigText?: string;
  avatarAddresses?: string[];
  maxAvatarsToShow?: number;
}

interface StatsSectionProps {
  stats: Stat[];
}

export default function StatsSection({ stats: _stats }: StatsSectionProps) {
  const stats = [
    {
      title: 'Community size',
      bigText: `${562}`,
    },
    {
      title: 'Community managers',
      maxAvatarsToShow: 3,
      avatarAddresses: [
        '0x0000000000000000000000000000000000000001',
        '0x0000000000000000000000000000000000000002',
        '0x0000000000000000000000000000000000000003',
        '0x0000000000000000000000000000000000000004',
        '0x0000000000000000000000000000000000000005',
        '0x0000000000000000000000000000000000000006',
        '0x0000000000000000000000000000000000000007',
      ],
    },
    {
      title: 'People you may know',
      maxAvatarsToShow: 2,
      avatarAddresses: [
        '0xEE599e7c078cAdBB7f81531322d6534F22749136',
        '0x0000000000000000000000000000000000000002',
        '0x0000000000000000000000000000000000000003',
      ],
    },
  ];

  return (
    <>
      {stats.map(stat => (
        <StatSection
          key={stat.title}
          title={stat.title}
          bigText={stat.bigText}
          maxAvatarsToShow={stat.maxAvatarsToShow}
          avatarAddresses={stat.avatarAddresses}
        />
      ))}
    </>
  );
}
