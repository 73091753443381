import { useWalletManager } from '@metaphor-xyz/hooks/dist';
import { createStyles, Loading } from '@metaphor-xyz/ui';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';

import BreadcrumbsView from '../components/BreadcrumbsView';
import ChoosePositionStep from '../components/DAOOnboardingFlow/ChoosePositionStep';
import ConnectWalletStep from '../components/DAOOnboardingFlow/ConnectWalletStep';
import ExchangeStep from '../components/DAOOnboardingFlow/ExchangeStep';
import StatusStep from '../components/DAOOnboardingFlow/StatusStep';
import SuccessStep from '../components/DAOOnboardingFlow/SuccessStep';
import useApplications from '../hooks/useApplications';
import useOrganization from '../hooks/useOrganization';
import { OnboardingFlowStack, OnboardingFlowStackParams, StackParams } from '../navigation';

export default function OnboardingNavigation({ route }: StackScreenProps<StackParams, 'onboard'>) {
  const styles = useStyles();
  const { loading, applications } = useApplications();
  const { loading: loadingOrganization, organization } = useOrganization(route.params.orgEnsName);
  const { connected, connecting } = useWalletManager();
  const [initialRouteName, setInitialRouteName] = useState<keyof OnboardingFlowStackParams | undefined>(undefined);

  useEffect(() => {
    if (!connecting && !loading && !loadingOrganization) {
      const roles = organization?.roles;

      if (applications && roles) {
        const committeeIds = roles.map(r => r.committeeId);

        for (const application of applications) {
          if (committeeIds.includes(application.committee.id)) {
            // todo(carlos): prefer to use GQL enum type, but caused build errors
            switch (application.status) {
              case 'IN_PROGRESS':
                setInitialRouteName('approval');
                break;
              case 'APPROVED':
                setInitialRouteName('exchange');
                break;
              case 'REJECTED':
                // TODO: figure out this state
                break;
            }
          }
        }
      } else if (connected) {
        setInitialRouteName('roles');
      } else {
        setInitialRouteName('connect');
      }
    }
  }, [applications, connected, organization, connecting, loading, loadingOrganization]);

  if (connecting || loading || loadingOrganization || !initialRouteName) {
    return <Loading size="large" />;
  }

  return (
    <OnboardingFlowStack.Navigator
      screenOptions={{
        title: 'Onboarding Flow',
        header: () => <BreadcrumbsView orgEnsName={route.params.orgEnsName} />,
        cardStyle: styles.cardStyle,
      }}
      initialRouteName={initialRouteName}
    >
      <OnboardingFlowStack.Screen name="connect" component={ConnectWalletStep} initialParams={route.params} />
      <OnboardingFlowStack.Screen name="roles" component={ChoosePositionStep} initialParams={route.params} />
      <OnboardingFlowStack.Screen name="approval" component={StatusStep} initialParams={route.params} />
      <OnboardingFlowStack.Screen name="exchange" component={ExchangeStep} initialParams={route.params} />
      <OnboardingFlowStack.Screen name="success" component={SuccessStep} initialParams={route.params} />
    </OnboardingFlowStack.Navigator>
  );
}

const useStyles = createStyles(theme => ({
  cardStyle: {
    backgroundColor: theme.colors.background,
  },
}));
