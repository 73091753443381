import { useQuery, gql } from '@apollo/client';
import { useWalletManager } from '@metaphor-xyz/hooks';
import { Token, CurrencyAmount, TradeType } from '@uniswap/sdk-core';
import { AlphaRouter, SwapRoute } from '@uniswap/smart-order-router';
import { ethers } from 'ethers';
import { useState, useEffect, useCallback } from 'react';

import { TokenSwapInfoQuery, TokenSwapInfoQueryVariables } from '../__generated__/gqlTypes';
import { NETWORK } from '../config';

const V3_SWAP_ROUTER_ADDRESS = '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45';

const QUERY = gql`
  query TokenSwapInfo($network: Network!, $inTokenId: ID!, $outTokenId: ID!) {
    inToken: token(id: $inTokenId, network: $network) @client {
      id
      name
      symbol
      decimals
      balance
    }

    outToken: token(id: $outTokenId, network: $network) @client {
      id
      name
      symbol
      decimals
      balance
    }
  }
`;

export interface TokenSwapOptions {
  inTokenId: string;
  outTokenId: string;
  outTokenAmount: ethers.BigNumber;
}

export default function useTokenSwap({ inTokenId, outTokenId, outTokenAmount }: TokenSwapOptions) {
  const { data, loading } = useQuery<TokenSwapInfoQuery, TokenSwapInfoQueryVariables>(QUERY, {
    variables: { inTokenId, outTokenId, network: NETWORK },
  });
  const { getProvider, getSigner } = useWalletManager();
  const [router, setRouter] = useState<AlphaRouter | null>(null);
  const [route, setRoute] = useState<SwapRoute | null>(null);
  const [chainId, setChainId] = useState(1);

  useEffect(() => {
    (async () => {
      const provider = await getProvider();
      const network = await provider.getNetwork();

      setChainId(network.chainId);
      setRouter(new AlphaRouter({ chainId: network.chainId, provider }));
    })();
  }, [getProvider]);

  useEffect(() => {
    const inTokenInfo = data?.inToken;
    const outTokenInfo = data?.outToken;
    if (NETWORK !== 'local' && router && inTokenInfo && outTokenInfo) {
      (async () => {
        const inToken = new Token(chainId, inTokenInfo.id, inTokenInfo.decimals, inTokenInfo.symbol, inTokenInfo.name);
        const outToken = new Token(
          chainId,
          outTokenInfo.id,
          outTokenInfo.decimals,
          outTokenInfo.symbol,
          outTokenInfo.name
        );
        setRoute(
          await router.route(
            CurrencyAmount.fromRawAmount(outToken, outTokenAmount.toString()),
            inToken,
            TradeType.EXACT_OUTPUT
          )
        );
      })();
    }
  }, [chainId, data, outTokenAmount, router]);

  const swap = useCallback(
    async (swapRoute: SwapRoute) => {
      const signer = await getSigner();

      if (!signer) {
        throw new Error('wallet not connected');
      }

      if (!swapRoute.methodParameters) {
        throw new Error('invalid swap route');
      }

      // todo(carlos): Check for approvals and request if needed

      return signer.sendTransaction({
        data: swapRoute.methodParameters.calldata,
        to: V3_SWAP_ROUTER_ADDRESS,
        value: ethers.BigNumber.from(swapRoute.methodParameters.value),
        gasPrice: ethers.BigNumber.from(swapRoute.gasPriceWei),
      });
    },
    [getSigner]
  );

  const cost = route?.quote;

  return { data, loading, cost, swap, route };
}
